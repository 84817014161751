import {
  BuyerGuidelineBody,
  BuyerGuidlineDtoModel,
} from "../../../model/buyerModel";
import { ListFetchQueryType } from "../../../model/global";
import {
  BuyerInformationBodyModel,
  IntermentFormDtoModel,
  PriceListBodyModel,
  PriceListDtoModel,
  RentalServicesCalendarDtoModel,
  RentalServicesCalendarQueryModel,
  SchedulePaymentReceiptDtoModel,
} from "../../../model/rentalServicesModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

export const rentalServicesEndpoints = {
  "rental-services-calendar-get": (
    type: string = "",
    from: string = "",
    to: string = ""
  ) => `/service/payment/list/${type}?from=${from}&to=${to}`,
  "rental-services-price-list": (
    type: string,
    page: number,
    entries: number,
    search: string
  ) =>
    `/service/price/list/${type}?perPage=${entries}&currentPage=${page}&search=${search}`,
  "add-rental-services-price-list": (type: string) =>
    `/service/price/add/${type}`,
  "update-rental-services-price-list": (type: string, id: number) =>
    `/service/price/add/${type}/${id}`,
  "delete-rental-services-price-list": (id: number) =>
    `/service/price/delete/${id}`,
  "add-schedule": (type: string) => `/service/schedule/add/${type}`,
  "get-schedule": (id: number) => `/service/schedule/get/${id}`,
  "add-schedule-payment": (type: string) => `/service/payment/add`,
  "get-schedule-payment": (id: number) => `/service/payment/get/${id}`,
  "get-receipt": (id: number, receiptType: string, paymentType: string) =>
    `/buyer/receipt/print/${id}/${receiptType}/${paymentType}`,
  "add-schedule-inventory": `/service/asset/add`,
  "get-schedule-inventory": (id: number) => `/service/asset/get/${id}`,
  "download-guidelines": (param: {
    buyerId: number;
    type: "RR" | "TC" | "GDL" | "PS";
  }) => `/buyer/agreement/download?buyerId=${param.buyerId}&type=${param.type}`,
  "interment-form": (id: number) => `/service/form/download/${id}`,
  waiver: (id: number) => `/service/waiver/download/${id}`,
  "add-buyer-information": "/buyer/information/add",
  "upload-requirement": "/service/document/upload",
  "add-requirement": "/service/document/add",
  "get-requirement": (param: any) => `/service/document/get/${param}`,
};

interface PriceListQuery extends ListFetchQueryType {
  type: string;
}

interface SavePriceListQuery {
  type: string;
  id: number;
}

export const rentalServicesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRentalServicesCalendar: builder.query<
      RentalServicesCalendarDtoModel,
      RentalServicesCalendarQueryModel
    >({
      query: (param) => ({
        url: rentalServicesEndpoints["rental-services-calendar-get"](
          param.type,
          param.from,
          param.to
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["rental-services-calendar-get"],
    }),
    getPriceList: builder.query<PriceListDtoModel, PriceListQuery>({
      query: (param) => ({
        url: rentalServicesEndpoints["rental-services-price-list"](
          param.type,
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        {
          type: "rental-services-price-list",
          id: args.type + 1,
        },
      ],
    }),
    addPriceList: builder.mutation<
      void,
      {
        queryParams: string;
        bodyParams: PriceListBodyModel;
      }
    >({
      query: (param) => ({
        url: rentalServicesEndpoints["add-rental-services-price-list"](
          param.queryParams
        ),
        method: "POST",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["rental-services-price-list"],
    }),
    updatePriceList: builder.mutation<
      void,
      {
        queryParams: SavePriceListQuery;
        bodyParams: PriceListBodyModel;
      }
    >({
      query: (param) => ({
        url: rentalServicesEndpoints["update-rental-services-price-list"](
          param.queryParams.type,
          param.queryParams.id
        ),
        method: "POST",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["rental-services-price-list"],
    }),
    deletePriceList: builder.mutation<void, number>({
      query: (param) => ({
        url: rentalServicesEndpoints["delete-rental-services-price-list"](
          param
        ),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["rental-services-price-list"],
    }),
    addSchedule: builder.mutation<
      any,
      {
        queryParams: string;
        bodyParams: any;
      }
    >({
      query: (param) => ({
        url: rentalServicesEndpoints["add-schedule"](param.queryParams),
        method: "POST",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getSchedule: builder.query<any, number>({
      query: (param) => ({
        url: rentalServicesEndpoints["get-schedule"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "add-schedule",
          id: arg,
        },
      ],
    }),
    addSchedulePayment: builder.mutation<
      any,
      {
        queryParams: string;
        bodyParams: any;
      }
    >({
      query: (param) => ({
        url: rentalServicesEndpoints["add-schedule-payment"](param.queryParams),
        method: "POST",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getSchedulePayment: builder.query<any, number>({
      query: (param) => ({
        url: rentalServicesEndpoints["get-schedule-payment"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "add-schedule-payment",
          id: arg,
        },
      ],
    }),
    getServiceReceipt: builder.query<
      SchedulePaymentReceiptDtoModel,
      {
        id: number;
        receiptType: string;
        paymentType: string;
      }
    >({
      query: (param) => ({
        url: rentalServicesEndpoints["get-receipt"](
          param.id,
          param.receiptType,
          param.paymentType
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "service-payment-receipt",
          id: arg.id,
        },
      ],
    }),
    addScheduleInventory: builder.mutation<
      any,
      {
        bodyParams: any;
      }
    >({
      query: (param) => ({
        url: rentalServicesEndpoints["add-schedule-inventory"],
        method: "POST",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["add-schedule-inventory"],
    }),
    getScheduleInventory: builder.query<any, number>({
      query: (param) => ({
        url: rentalServicesEndpoints["get-schedule-inventory"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "add-schedule-inventory",
          id: arg,
        },
      ],
    }),
    getServiceGuideline: builder.query<
      BuyerGuidlineDtoModel,
      {
        buyerId: number;
        type: "RR" | "TC" | "GDL" | "PS";
      }
    >({
      query: (param) => ({
        url: rentalServicesEndpoints["download-guidelines"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "buyer-guideline",
          id: `${arg.buyerId}-${arg.type}`,
        },
      ],
    }),
    getIntermentForm: builder.query<IntermentFormDtoModel, number>({
      query: (param) => ({
        url: rentalServicesEndpoints["interment-form"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "rental-services-interment-form",
          id: arg,
        },
      ],
    }),
    getWaiver: builder.query<IntermentFormDtoModel, number>({
      query: (param) => ({
        url: rentalServicesEndpoints["waiver"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "rental-services-waiver",
          id: arg,
        },
      ],
    }),
    addBuyerInfo: builder.mutation<
      {
        success: string;
        data: {
          id: number;
          referenceNumber: string;
        };
        msg: string;
      },
      any
    >({
      query: (body) => ({
        url: rentalServicesEndpoints["add-buyer-information"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    addRequirement: builder.mutation<void, any>({
      query: (body) => ({
        url: rentalServicesEndpoints["add-requirement"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "rental-services-requirement",
          id: `${arg.id}`,
        },
      ],
    }),
    getRequirement: builder.query<any, any>({
      query: (param) => ({
        url: rentalServicesEndpoints["get-requirement"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "rental-services-requirement",
          id: `${arg.id}`,
        },
      ],
    }),
  }),
});

export const {
  useGetRentalServicesCalendarQuery,
  useGetPriceListQuery,
  useAddPriceListMutation,
  useUpdatePriceListMutation,
  useDeletePriceListMutation,
  useAddScheduleMutation,
  useGetScheduleQuery,
  useAddSchedulePaymentMutation,
  useGetSchedulePaymentQuery,
  useLazyGetServiceReceiptQuery,
  useAddScheduleInventoryMutation,
  useGetScheduleInventoryQuery,
  useLazyGetServiceGuidelineQuery,
  useLazyGetIntermentFormQuery,
  useLazyGetWaiverQuery,
  useAddBuyerInfoMutation,
  useAddRequirementMutation,
  useGetRequirementQuery,
} = rentalServicesSlice;
