import _ from "lodash";
import {
  useForm,
  FormProvider,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import CustomFileUploader from "../../common/textfields/CustomFileUploader";
import { linkWithDomain } from "../../../utils/helpers/stringManipulate";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { skipToken } from "@reduxjs/toolkit/query";
import { LoaderWithText } from "../../common/Loaders";
import {
  rentalServicesEndpoints,
  useAddRequirementMutation,
  useGetRequirementQuery,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";

// import "./index.css";

interface UploadForm {
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
}

type Props = {
  title: string;
  type: any;
  uploadTitle: string;
  scheduleForm: any;
};

const RequirementsUploader = ({
  title,
  type,
  uploadTitle,
  scheduleForm,
}: Props) => {
  const form = useForm<UploadForm>({
    defaultValues: {
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
    },
  });

  const [
    addMutate,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddRequirementMutation();

  const {
    data: guidelineData,
    isLoading: guidelineIsLoading,
    error: guidelineError,
    isFetching: guidelineIsFetching,
    refetch: guidelineRefetch
  } = useGetRequirementQuery(
    scheduleForm.watch("servicePaymentTermId")
      ? scheduleForm.watch("servicePaymentTermId")
      : skipToken
  );

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset: fieldReset,
  } = form;

  const loading = guidelineIsLoading || guidelineIsFetching;

  const documentWatch = useWatch({
    control,
    name: "document",
  });
console.log(type.id);


  const onSubmit: SubmitHandler<UploadForm> = (dataFields) => {
    addMutate({
      servicePaymentTermId: scheduleForm.watch("servicePaymentTermId"),
      documents: [
        {
          fileName: dataFields.document[0].name,
          path: dataFields.document[0].path,
          serviceRequiredDocumentId: type?.id,
        },
      ],
    });
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: `add ${title}`,
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: `${title} updated!`,
      toastId: `add ${title}`,
    });
    addReset();
    fieldReset();
    guidelineRefetch();
  }

  if (guidelineError) {
    errorDisplayOrNavigate({
      error: guidelineError,
      toastId: title,
    });
  }

  return loading ? (
    <LoaderWithText text={`Getting ${title} data`} />
  ) : (
    <Stack direction={{ lg: "row", xs: "column" }} spacing={2} marginBottom={6}>
      <Box
        height={guidelineData?.data ? 600 : "auto"}
        width={{ lg: "50%", xs: "100%" }}
      >
        <Typography variant="textfieldLabel">{title}</Typography>
        {guidelineData?.data && guidelineData?.data?.find((data: any) => data?.serviceRequiredDocument?.id === type?.id) ? (
          <DocViewer
            documents={[
              {
                uri: linkWithDomain(
                  guidelineData?.data?.find((data: any) => data?.serviceRequiredDocument?.id === type?.id)?.path ? guidelineData?.data?.find((data: any) => data?.serviceRequiredDocument?.id === type?.id).path : ""
                ),
              },
            ]}
            pluginRenderers={DocViewerRenderers}
          />
        ) : (
          <Alert severity="warning">{`No File for ${title}`}</Alert>
        )}
      </Box>
      <Box
        height={guidelineData?.data ? 600 : "auto"}
        width={{ lg: "50%", xs: "100%" }}
      >
        <Typography variant="textfieldLabel">{uploadTitle}</Typography>
        <FormProvider {...form}>
          <CustomFileUploader
            name="document"
            setValue={setValue}
            clearErrors={clearErrors}
            errorMsg={errors.document?.message}
            endpoint={rentalServicesEndpoints["upload-requirement"]}
            getValues={getValues}
            isMultiple={false}
          />
          <Button
            variant="button-primary"
            sx={{
              width: 150,
              marginTop: 2,
            }}
            disabled={_.isEmpty(documentWatch[0].path)}
            startIcon={
              addIsLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={addIsLoading ? () => {} : handleSubmit(onSubmit)}
          >
            Upload
          </Button>
        </FormProvider>
        {!_.isEmpty(documentWatch[0].path) && (
          <Box marginTop={2} height={"428px"}>
            <DocViewer
              documents={[
                {
                  uri: linkWithDomain(documentWatch[0].path),
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default RequirementsUploader;
