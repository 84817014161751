import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { thisFieldRequired } from "../../../utils/constants";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import {
  useAddPriceListMutation,
  useUpdatePriceListMutation,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
import { rentalAndServicesRoute } from "../../../routes/routeKeys";
import IconButtonMdi from "../../common/IconButtonMdi";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { Checkbox, FormControlLabel, useTheme } from "@mui/material";

interface PriceListFormType {
  id?: number | undefined;
  name: string;
  regular: string;
  holiday: string;
  sp: string;
  spHoliday: string;
  inclusions: string;
  canEditName: number;
  documents: { name: string; required: boolean }[];
  assets: { name: string; quantity: number | null; amount: number | null }[];
}

type Props = {
  serviceType: string;
  title: string;
  data: PriceListModel | null;
  closeFn: () => void;
  scheduleForm: any;
};

const AddServiceForm = ({
  serviceType,
  title,
  data,
  closeFn,
  scheduleForm,
}: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const theme = useTheme();

  const form = useForm<PriceListFormType>({
    defaultValues: {
      id: undefined,
      name: "",
      regular: "",
      holiday: "",
      sp: "",
      spHoliday: "",
      inclusions: "",
      canEditName: 1,
      documents: [{ name: "", required: false }],
      assets: [{ name: "", quantity: null, amount: null }],
    },
  });

  const { control, setValue, reset: fieldReset, handleSubmit, setError } = form;

  const {
    fields: documentsFields,
    append: documentsAppend,
    remove: documentsRemove,
    replace: documentsReplace,
  } = useFieldArray({
    control,
    name: "documents",
  });

  const documentsWatch = useWatch({
    control,
    name: "documents",
  });

  const {
    fields: assetsFields,
    append: assetsAppend,
    remove: assetsRemove,
    replace: assetsReplace,
  } = useFieldArray({
    control,
    name: "assets",
  });

  const assetsWatch = useWatch({
    control,
    name: "assets",
  });

  const [
    addMutation,
    {
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddPriceListMutation();
  const [
    updateMutation,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdatePriceListMutation();

  const saveLoading = addIsLoading || updateIsLoading;

  const onError = () => {};

  const handleAddRemoveFieldArray = (index: number | null, key: string) => {
    if (typeof index === "number") {
      if (key === "documents") {
        documentsRemove(index);
      } else {
        assetsRemove(index);
      }
    } else {
      if (key === "documents") {
        documentsAppend({
          name: "",
          required: false,
        });
      } else {
        assetsAppend({
          name: "",
          quantity: null,
          amount: null,
        });
      }
    }
  };

  const onSubmit: SubmitHandler<PriceListFormType> = (dataFields) => {
    const {
      name,
      regular,
      holiday,
      sp,
      spHoliday,
      inclusions,
      documents,
      assets,
    } = dataFields;

    if (data) {
      updateMutation({
        queryParams: {
          type: serviceType.toUpperCase(),
          id: data.id,
        },
        bodyParams: {
          name,
          regular,
          holiday,
          sp,
          spHoliday,
          inclusions,
          documents: documents?.filter((doc) => doc.name),
          assets: assets?.filter((asset) => asset.name),
        },
      });
    } else {
      addMutation({
        queryParams: serviceType.toUpperCase(),
        bodyParams: {
          name,
          regular,
          holiday,
          sp,
          spHoliday,
          inclusions,
          documents,
          assets,
        },
      });
    }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-rental-services-price-list",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: `New ${serviceType.toLowerCase()} service successfully added!`,
      toastId: "add-rental-services-price-list",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: `${_.capitalize(serviceType)} service successfully updated!`,
      toastId: "update-rental-services-price-list",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-rental-services-price-list",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const {
        id,
        name,
        regular,
        holiday,
        sp,
        spHoliday,
        inclusions,
        canEditName,
        serviceIncludedAssets,
        serviceRequiredDocuments,
      } = data;

      setValue("id", id);
      setValue("name", name);
      setValue("regular", regular);
      setValue("holiday", holiday);
      setValue("sp", sp);
      setValue("spHoliday", spHoliday);
      setValue("inclusions", inclusions);
      setValue("canEditName", canEditName);
      setValue(
        "assets",
        serviceIncludedAssets?.length > 0
          ? serviceIncludedAssets
          : [{ name: "", quantity: null, amount: null }]
      );
      setValue(
        "documents",
        serviceRequiredDocuments?.length > 0
          ? serviceRequiredDocuments
          : [{ name: "", required: false }]
      );
    }
  }, [data]);

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        <FormProvider {...form}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="textfieldLabel">Service</Typography>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Service"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        disabled={form.watch("canEditName") === 0}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">Regular Rate</Typography>
                  <Controller
                    name="regular"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        placeholder="Amount"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">
                    Holiday Rate (Regular)
                  </Typography>
                  <Controller
                    name="holiday"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        placeholder="Amount"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">Documents</Typography>
                  {documentsFields.map((item, key) => (
                    <Stack
                      direction="row"
                      spacing={1}
                      key={key}
                      paddingTop={key === 0 ? 1 : 2}
                    >
                      <IconButtonMdi
                        mdiIcon={
                          key + 1 === documentsWatch.length
                            ? mdiPlusCircleOutline
                            : mdiMinusCircleOutline
                        }
                        iconColor={
                          key + 1 === documentsWatch.length
                            ? theme.palette.info.main
                            : theme.palette.error.main
                        }
                        onClick={() =>
                          handleAddRemoveFieldArray(
                            key + 1 === documentsWatch.length ? null : key,
                            "documents"
                          )
                        }
                      />
                      <Box width="100%">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            columnGap: 10,
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Controller
                              name={`documents.${key}.name`}
                              control={control}
                              rules={{
                                required: {
                                  value: form.watch(`documents.${key}.required`)
                                    ? true
                                    : false,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  placeholder="Name"
                                  error={fieldState.error ? true : false}
                                  helperText={fieldState.error?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                  value={form.watch("documents")[key]?.name}
                                />
                              )}
                            />
                          </div>
                          <div style={{ width: 100 }}>
                            <Controller
                              name={`documents.${key}.required`}
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  sx={{
                                    label: {
                                      fontSize: ".9rem",
                                    },
                                  }}
                                  label="Required"
                                  control={
                                    <Checkbox
                                      {...field}
                                      checked={field.value}
                                      value={
                                        form.watch("documents")[key]?.required
                                      }
                                    />
                                  }
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="textfieldLabel">
                    Senior/PWD Rate
                  </Typography>
                  <Controller
                    name="sp"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        placeholder="Amount"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">
                    Holiday Rate (Senior/PWD)
                  </Typography>
                  <Controller
                    name="spHoliday"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        placeholder="Amount"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">Inclusions</Typography>
                  <Controller
                    name="inclusions"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Inclusions"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        // InputProps={{
                        //   readOnly: data ? true : false,
                        // }}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">Assets</Typography>
                  {assetsFields.map((item, key) => (
                    <Stack
                      direction="row"
                      spacing={1}
                      key={key}
                      paddingTop={key === 0 ? 1 : 2}
                    >
                      <IconButtonMdi
                        mdiIcon={
                          key + 1 === assetsWatch.length
                            ? mdiPlusCircleOutline
                            : mdiMinusCircleOutline
                        }
                        iconColor={
                          key + 1 === assetsWatch.length
                            ? theme.palette.info.main
                            : theme.palette.error.main
                        }
                        onClick={() =>
                          handleAddRemoveFieldArray(
                            key + 1 === assetsWatch.length ? null : key,
                            "assets"
                          )
                        }
                      />
                      <Box width="100%">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            columnGap: 10,
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Controller
                              name={`assets.${key}.name`}
                              control={control}
                              rules={{
                                required: {
                                  value:
                                    form.watch(`assets.${key}.quantity`) ||
                                    form.watch(`assets.${key}.amount`)
                                      ? true
                                      : false,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  placeholder="Name"
                                  error={fieldState.error ? true : false}
                                  helperText={fieldState.error?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                  value={form.watch("assets")[key]?.name}
                                />
                              )}
                            />
                          </div>
                          <div style={{ width: 150 }}>
                            <Controller
                              name={`assets.${key}.quantity`}
                              control={control}
                              rules={{
                                required: {
                                  value:
                                    form.watch(`assets.${key}.name`) ||
                                    form.watch(`assets.${key}.amount`)
                                      ? true
                                      : false,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="Quantity"
                                  error={fieldState.error ? true : false}
                                  helperText={fieldState.error?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                  value={form.watch("assets")[key]?.quantity}
                                />
                              )}
                            />
                          </div>
                          <div style={{ width: 150 }}>
                            <Controller
                              name={`assets.${key}.amount`}
                              control={control}
                              rules={{
                                required: {
                                  value:
                                    form.watch(`assets.${key}.name`) ||
                                    form.watch(`assets.${key}.quantity`)
                                      ? true
                                      : false,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="Amount"
                                  error={fieldState.error ? true : false}
                                  helperText={fieldState.error?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                  value={form.watch("assets")[key]?.amount}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          />
          <Button
            variant="button-primary"
            sx={{
              width: 150,
            }}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
          >
            {data ? "Update" : "Save"}
          </Button>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default AddServiceForm;
