import React, { useEffect, useRef, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { LoaderWithText } from "../../common/Loaders";
import { useGetPaymentsListConfigQuery } from "../../../service/slice/system-configuration/paymentsTypesSlice";
import { useGetUserListConfigQuery } from "../../../service/slice/usersSlice";
import { RoleTypeEnum } from "../../../utils/enums";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import Particulars from "./Particulars";
import { getUserCredential } from "../../../utils/helpers/storageHelper";
import {
  replaceAllHyphenWithSpace,
  replaceAllSpaceWithHyphen,
} from "../../../utils/helpers/stringManipulate";
import { thisFieldRequired } from "../../../utils/constants";
import {
  useAddVoucherMutation,
  useLazyGetVoucherDocQuery,
  useGetVoucherDetailsQuery,
  useUpdateVoucherMutation,
} from "../../../service/slice/account-management/voucher/vouchersSlice";
import { PaymentFieldsBody } from "../../../model/buyerModel";
import { useGetAgentListConfigQuery } from "../../../service/slice/usersSlice";
import useFileView from "../../../hooks/useFileView";
import FileViewModal from "../../common/FileViewModal";
import { skipToken } from "@reduxjs/toolkit/query";
import { VoucherModel } from "../../../model/voucherModel";
import VoucherUpload from "./VoucherUpload";

type SelectBox = {
  label: string;
  value: number | null;
};

export interface VoucherForm {
  userType: "EXTERNAL" | "INTERNAL" | "";
  receivedByType: "EXTERNAL" | "INTERNAL" | "";
  agent: SelectBox;
  receivedBy: SelectBox;
  checkedBy: SelectBox;
  approvedBy: SelectBox;
  approved: boolean;
  particulars: {
    amount: string | number;
    particular: string;
    particularId?: number;
  }[];
  paymentType: SelectBox;
  paymentFields: {
    [name: string]: string | Date | null | undefined;
  }[];
}

type Props<T> = {
  title: string;
  data: T | null;
  type: "CASH" | "CHECK";
  closeFn: () => void;
};

const CreateVoucher = <T extends VoucherModel>({
  title,
  closeFn,
  data,
  type,
}: Props<T>) => {
  const user = getUserCredential();
  const paymentFieldRef = useRef<string[]>([]);
  const paymentFieldIdRef = useRef<number[]>([]);
  const updateAddSuccess = useRef<boolean>(false);
  const [viewDoc, handelToggleViewDoc] = useFileView();
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const form = useForm<VoucherForm>({
    defaultValues: {
      userType: "",
      receivedByType: "",
      paymentType: {
        label: "",
        value: null,
      },
      paymentFields: [],
      agent: {
        label: "",
        value: null,
      },
      approvedBy: {
        label: "",
        value: null,
      },
      checkedBy: {
        label: "",
        value: null,
      },
      receivedBy: {
        label: "",
        value: null,
      },
      approved: false,
      particulars: [
        {
          amount: "",
          particular: "",
        },
      ],
    },
  });

  const {
    control,
    clearErrors,
    setValue,
    formState: { errors },
    setError,
    getValues,
    handleSubmit,
  } = form;

  const {
    data: agentListData,
    isLoading: agentListLoading,
    error: agentListError,
  } = useGetAgentListConfigQuery();

  const {
    data: userListData,
    isLoading: userListLoading,
    error: userListError,
  } = useGetUserListConfigQuery();

  const {
    data: details,
    isLoading: detailsIsLoading,
    error: detailsError,
  } = useGetVoucherDetailsQuery(
    data
      ? {
          id: data.id,
          type,
        }
      : skipToken
  );

  const [
    getDoc,
    {
      data: docData,
      isLoading: docIsLoading,
      error: docError,
      isSuccess: docIsSuccess,
    },
  ] = useLazyGetVoucherDocQuery();

  const {
    data: paymentListData,
    isLoading: paymentListIsLoading,
    error: paymentListError,
  } = useGetPaymentsListConfigQuery();

  const [
    addMutate,
    {
      data: addData,
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddVoucherMutation();

  const [
    updateMutate,
    {
      isLoading: updateIsLoading,
      isSuccess: updateIsSuccess,
      error: updateError,
      reset: updateReset,
    },
  ] = useUpdateVoucherMutation();

  const loading =
    paymentListIsLoading ||
    userListLoading ||
    agentListLoading ||
    detailsIsLoading;
  const saveLoading = addIsLoading || docIsLoading || updateIsLoading;

  const { fields: paymentFieldsWatch, replace: paymentFieldsReplace } =
    useFieldArray({
      control,
      name: "paymentFields",
    });

  const paymentTypeWatch = useWatch({ control, name: "paymentType" });
  const userTypeWatch = useWatch({ control, name: "userType" });
  const receivedByTypeWatch = useWatch({ control, name: "receivedByType" });

  const toggleUploadDoc = () => setOpenUpload((prev) => !prev);

  const agentOpt = useMemo(
    () =>
      agentListData?.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
    [agentListData]
  );

  const usersOpt = useMemo(() => {
    return {
      users: userListData?.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
      admins: userListData?.data
        .filter(
          (item) =>
            item.role.name === "Super-Admin" || item.role.name === "Admin"
        )
        .map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        })),
    };
  }, [userListData]);

  const paymentTypeOptions = useMemo(() => {
    return (
      paymentListData?.data.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [paymentListData?.data]);

  const handlePaymentTypeOnChange = (value: {
    label: string;
    value: number | null;
  }) => {
    const selectedPayType = paymentListData?.data?.find(
      (item) => item.id === Number(value.value)
    );

    if (selectedPayType) {
      paymentFieldsReplace(
        selectedPayType.paymentFields.map(
          (item): { [name: string]: string } => ({
            [`${replaceAllSpaceWithHyphen(item.entries)}`]: "",
          })
        )
      );
      paymentFieldRef.current = selectedPayType.paymentFields.map(
        (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
      );
      paymentFieldIdRef.current = selectedPayType.paymentFields.map(
        (item) => item.id
      );

      setValue("paymentType", value);

      setError("paymentType", {
        type: "required",
        message: "",
      });
    }
  };

  const handlePayToChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const currentVal = event.currentTarget.value;
    if (currentVal === "EXTERNAL") {
      setValue("userType", "EXTERNAL");
      setError("userType", {
        message: "",
        type: "required",
      });
    } else if (currentVal === "INTERNAL") {
      setValue("userType", "INTERNAL");
      setError("userType", {
        message: "",
        type: "required",
      });
    }
    setValue("agent", {
      label: "",
      value: null,
    });
  };

  const handleReceiveToChange = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const currentVal = event.currentTarget.value;
    if (currentVal === "EXTERNAL") {
      setValue("receivedByType", "EXTERNAL");
      setError("receivedByType", {
        message: "",
        type: "required",
      });
    } else if (currentVal === "INTERNAL") {
      setValue("receivedByType", "INTERNAL");
      setError("receivedByType", {
        message: "",
        type: "required",
      });
    }
    setValue("receivedBy", {
      label: "",
      value: null,
    });
  };

  const checkErrors = (): number[] => {
    const {
      agent,
      approvedBy,
      checkedBy,
      particulars,
      paymentType,
      receivedBy,
      userType,
      receivedByType,
    } = getValues();
    let errors: number[] = [];

    if (paymentType.value === null) {
      setError("paymentType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    if (agent.value === null && !_.isEmpty(userType)) {
      setError("agent", { type: "required", message: thisFieldRequired });
      errors.push(1);
    }

    if (approvedBy.value === null) {
      setError("approvedBy", { type: "required", message: thisFieldRequired });
      errors.push(1);
    }

    if (checkedBy.value === null) {
      setError("checkedBy", { type: "required", message: thisFieldRequired });
      errors.push(1);
    }

    if (receivedBy.value === null && !_.isEmpty(receivedByType)) {
      setError("receivedBy", { type: "required", message: thisFieldRequired });
      errors.push(1);
    }

    return errors;
  };

  const onError = () => checkErrors();

  const onSubmitSave: SubmitHandler<VoucherForm> = (dataFields) => {
    const errorCount = checkErrors();

    if (errorCount.length > 0) {
      return;
    }

    const {
      agent,
      approvedBy,
      checkedBy,
      particulars,
      paymentFields,
      paymentType,
      receivedBy,
      userType,
      receivedByType,
    } = dataFields;

    let finalPaymentAmount: string | number = 0;

    paymentFields.forEach((item, key) => {
      const itemKeyName = paymentFieldRef.current[key];

      if (
        itemKeyName.toLowerCase().includes("cash") ||
        itemKeyName.toLowerCase().includes("amount")
      ) {
        finalPaymentAmount = item[itemKeyName] as string;
      }
    });

    const finalPaymentFields: PaymentFieldsBody[] = paymentFields.map(
      (item, key) => {
        const itemId = paymentFieldIdRef.current[key];
        const itemKeyName = paymentFieldRef.current[key];
        return {
          fieldId: itemId,
          value: itemKeyName.toLowerCase().includes("date")
            ? (moment(item[itemKeyName]).format("YYYY-MM-DD") as string)
            : (item[itemKeyName] as string),
        };
      }
    );

    if (data) {
      updateMutate({
        id: details?.data.id as number,
        type,
        body: {
          userType,
          receivedByType,
          agentId: agent.value as number,
          approved: 1,
          approvedBy: approvedBy.value as number,
          checkedBy: checkedBy.value as number,
          receivedBy: receivedBy.value as number,
          payment: {
            id: details?.data.payment.paymentId as number,
            paymentId: Number(paymentType.value),
            amount: finalPaymentAmount,
            paymentFields: finalPaymentFields,
          },
          particulars: particulars.map((item) => ({
            id: item?.particularId ?? undefined,
            amount: item.amount,
            particular: item.particular,
          })),
        },
      });
    } else {
      addMutate({
        type,
        body: {
          userType,
          receivedByType,
          agentId: agent.value as number,
          approved: 1,
          approvedBy: approvedBy.value as number,
          checkedBy: checkedBy.value as number,
          payment: {
            paymentId: Number(paymentType.value),
            amount: finalPaymentAmount,
            paymentFields: finalPaymentFields,
          },
          particulars: particulars.map((item) => ({
            amount: item.amount,
            particular: item.particular,
          })),
          receivedBy: receivedBy.value as number,
        },
      });
    }
  };

  if (detailsError) {
    errorDisplayOrNavigate({
      error: detailsError,
      toastId: "voucher-details",
    });
  }

  if (docError) {
    errorDisplayOrNavigate({
      error: docError,
      toastId: "voucher-doc",
    });
  }

  if (agentListError) {
    errorDisplayOrNavigate({
      error: agentListError,
      toastId: "agent-list",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      toastId: "add-voucher",
      text: "Voucher Successfully Created!",
    });

    getDoc({
      id: addData?.data?.typeId ?? 0,
      type,
    });

    updateAddSuccess.current = true;
    addReset();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      toastId: "update-voucher",
      text: "Voucher Successfully Updated!",
    });

    getDoc({
      id: details?.data.id ?? 0,
      type,
    });

    updateAddSuccess.current = true;
    updateReset();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-voucher",
    });

    updateReset();
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-voucher",
    });

    addReset();
  }

  if (paymentListError) {
    errorDisplayOrNavigate({
      error: paymentListError,
      toastId: "payment-list-config",
    });
  }

  if (userListError) {
    errorDisplayOrNavigate({
      error: userListError,
      toastId: "user-list-config",
    });
  }

  useEffect(() => {
    if (details?.data.id) {
      const {
        agent,
        approvedByCC,
        checkedByCC,
        payment,
        receivedByCC,
        receivedByType,
        userType,
      } = details.data;

      const { fields, paymentType } = payment;

      const selectedPayType = paymentListData?.data.find(
        (item) => item.id === paymentType.id
      );

      setValue("paymentType", {
        label: selectedPayType?.name ?? "",
        value: selectedPayType?.id ?? null,
      });

      setValue("userType", userType);
      setValue("receivedByType", receivedByType);

      setValue("agent", {
        label: `${agent.firstName} ${agent.lastName}`,
        value: agent.id as number,
      });

      setValue("approvedBy", {
        label: `${approvedByCC?.firstName ?? ""} ${
          approvedByCC?.lastName ?? ""
        }`,
        value: approvedByCC.id as number,
      });

      setValue("checkedBy", {
        label: `${checkedByCC?.firstName ?? ""} ${checkedByCC?.lastName ?? ""}`,
        value: checkedByCC.id as number,
      });

      setValue("receivedBy", {
        label: `${receivedByCC?.firstName ?? ""} ${
          receivedByCC?.lastName ?? ""
        }`,
        value: receivedByCC?.id as number,
      });

      if (selectedPayType) {
        paymentFieldsReplace(
          selectedPayType.paymentFields.map((item) => {
            const paymentValue = fields.find(
              (itemField) => itemField.field.id === item.id
            );

            const realValue = paymentValue?.field.entries
              .toLowerCase()
              .includes("date")
              ? new Date(paymentValue.value)
              : paymentValue?.value;

            return {
              [`${replaceAllSpaceWithHyphen(item.entries)}`]: realValue,
            };
          })
        );

        paymentFieldRef.current = selectedPayType.paymentFields.map(
          (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
        );
        paymentFieldIdRef.current = selectedPayType.paymentFields.map(
          (item) => item.id
        );
      }
    } else if (title.toLowerCase().includes("create")) {
      const propType = type.toLowerCase();
      const defaultPaymentType = paymentListData?.data.find(
        (item) => item.name.toLowerCase() === propType
      );

      if (defaultPaymentType) {
        paymentFieldsReplace(
          defaultPaymentType.paymentFields.map(
            (item): { [name: string]: string } => ({
              [`${replaceAllSpaceWithHyphen(item.entries)}`]: "",
            })
          )
        );
        paymentFieldRef.current = defaultPaymentType.paymentFields.map(
          (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
        );
        paymentFieldIdRef.current = defaultPaymentType.paymentFields.map(
          (item) => item.id
        );

        setValue("paymentType", {
          label: defaultPaymentType?.name ?? "",
          value: defaultPaymentType?.id ?? null,
        });
      }
    }
  }, [details, paymentListData, userListData, agentListData]);

  useEffect(() => {
    if (docIsSuccess) {
      handelToggleViewDoc(true, docData?.data.link ?? "");
    }
  }, [docIsSuccess]);

  return (
    <Box paddingBottom={2}>
      {openUpload && (
        <VoucherUpload
          handleToggleUpload={toggleUploadDoc}
          id={data?.id ?? 0}
          open={openUpload}
          title="Upload Documents"
          type={type}
        />
      )}
      {viewDoc.open && (
        <FileViewModal
          open={viewDoc.open}
          path={viewDoc.path}
          title={viewDoc.path.substring(
            viewDoc.path.lastIndexOf("/") + 1,
            viewDoc.path.length
          )}
          onClose={() => {
            handelToggleViewDoc(false, "");
            if (updateAddSuccess.current) {
              closeFn();
            }
          }}
        />
      )}
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={closeFn} />
        <Box padding={3}>
          {loading ? (
            <Box paddingX={2}>
              <Stack alignItems="center" justifyContent="center">
                <LoaderWithText text="Get Additional Details..." />
              </Stack>
            </Box>
          ) : (
            <>
              <FormProvider {...form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5}>
                    <Controller
                      rules={{
                        required: {
                          message: "Please Select a Type",
                          value: true,
                        },
                      }}
                      name={"userType"}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <>
                            {error?.message ? (
                              <Typography variant="textfieldLabel">
                                Pay To{" "}
                                <FormHelperText component={"span"} error>
                                  {`(${error.message})`}
                                </FormHelperText>
                              </Typography>
                            ) : (
                              <Typography variant="textfieldLabel">
                                Pay To
                              </Typography>
                            )}
                            <RadioGroup {...field} onChange={handlePayToChange}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems={"flex-start"}
                              >
                                <FormControlLabel
                                  value="EXTERNAL"
                                  label="External"
                                  control={<Radio />}
                                />
                                {(userTypeWatch === "EXTERNAL" ||
                                  userTypeWatch === "") && (
                                  <Box width="100%">
                                    <CustomReactSelectJs
                                      control={control}
                                      options={agentOpt}
                                      name="agent"
                                      placeholder="Pay To"
                                      isDisabled={_.isEmpty(userTypeWatch)}
                                    />
                                  </Box>
                                )}
                              </Stack>
                              <Stack
                                direction="row"
                                spacing={2}
                                marginTop={1}
                                alignItems={"flex-start"}
                              >
                                <FormControlLabel
                                  value="INTERNAL"
                                  label="Internal&nbsp;"
                                  control={<Radio />}
                                />
                                {(userTypeWatch === "INTERNAL" ||
                                  userTypeWatch === "") && (
                                  <Box width="100%">
                                    <CustomReactSelectJs
                                      control={control}
                                      options={usersOpt.users}
                                      name="agent"
                                      placeholder="Pay To"
                                      isDisabled={_.isEmpty(userTypeWatch)}
                                    />
                                  </Box>
                                )}
                              </Stack>
                            </RadioGroup>
                          </>
                        );
                      }}
                    />
                  </Grid>
                  <Grid container spacing={2} item xs={12}>
                    <Particulars
                      control={control}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      errors={errors}
                      details={details?.data}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Stack spacing={2}>
                      <div>
                        <Controller
                          rules={{
                            required: {
                              message: "Please Select a Type",
                              value: true,
                            },
                          }}
                          name={"receivedByType"}
                          render={({ field, fieldState: { error } }) => {
                            return (
                              <>
                                {error?.message ? (
                                  <Typography variant="textfieldLabel">
                                    Received By{" "}
                                    <FormHelperText component={"span"} error>
                                      {`(${error.message})`}
                                    </FormHelperText>
                                  </Typography>
                                ) : (
                                  <Typography variant="textfieldLabel">
                                    Received By
                                  </Typography>
                                )}
                                <RadioGroup
                                  {...field}
                                  onChange={handleReceiveToChange}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems={"flex-start"}
                                  >
                                    <FormControlLabel
                                      value="EXTERNAL"
                                      label="External"
                                      control={<Radio />}
                                    />
                                    {(receivedByTypeWatch === "EXTERNAL" ||
                                      receivedByTypeWatch === "") && (
                                      <Box width="100%">
                                        <CustomReactSelectJs
                                          control={control}
                                          options={agentOpt}
                                          name="receivedBy"
                                          placeholder="Received By"
                                          isDisabled={_.isEmpty(
                                            receivedByTypeWatch
                                          )}
                                        />
                                      </Box>
                                    )}
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    marginTop={1}
                                    alignItems={"flex-start"}
                                  >
                                    <FormControlLabel
                                      value="INTERNAL"
                                      label="Internal&nbsp;"
                                      control={<Radio />}
                                    />
                                    {(receivedByTypeWatch === "INTERNAL" ||
                                      receivedByTypeWatch === "") && (
                                      <Box width="100%">
                                        <CustomReactSelectJs
                                          control={control}
                                          options={usersOpt.users}
                                          name="receivedBy"
                                          placeholder="Received By"
                                          isDisabled={_.isEmpty(
                                            receivedByTypeWatch
                                          )}
                                        />
                                      </Box>
                                    )}
                                  </Stack>
                                </RadioGroup>
                              </>
                            );
                          }}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Checked By
                        </Typography>
                        <CustomReactSelectJs
                          control={control}
                          options={usersOpt.users}
                          name="checkedBy"
                          placeholder="Checked By"
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Approved By
                        </Typography>

                        <CustomReactSelectJs
                          control={control}
                          options={usersOpt.admins}
                          name="approvedBy"
                          placeholder="Approved By"
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Prepared By
                        </Typography>
                        <TextField
                          disabled
                          value={
                            details?.data
                              ? `${details.data.preparedByCC.firstName} ${details.data.preparedByCC.lastName}`
                              : `${user?.firstName} ${user?.lastName}`
                          }
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Payment Type
                        </Typography>
                        <CustomReactSelectJs
                          control={control}
                          options={paymentTypeOptions}
                          name="paymentType"
                          placeholder="Payment Type"
                          isDisabled={true}
                          // @ts-ignore
                          // customOnchange={handlePaymentTypeOnChange}
                        />
                      </div>
                      {!_.isNull(paymentTypeWatch.value) && (
                        <>
                          {paymentFieldsWatch.map((item, key) => {
                            const itemKeyName = Object.keys(item)[0];

                            const itemLabelName =
                              replaceAllHyphenWithSpace(itemKeyName);

                            return (
                              <div key={key}>
                                <Typography variant="textfieldLabel">
                                  {itemLabelName === "Cash"
                                    ? "Amount"
                                    : itemLabelName}
                                </Typography>
                                <Controller
                                  name={`paymentFields.${key}.${itemKeyName}`}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  control={control}
                                  render={({ field, fieldState }) => {
                                    if (
                                      itemKeyName.toLowerCase().includes("date")
                                    ) {
                                      return (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DatePicker
                                            {...field}
                                            slotProps={{
                                              textField: {
                                                variant: "outlined",
                                                error: fieldState.error?.message
                                                  ? true
                                                  : false,
                                                helperText:
                                                  fieldState.error?.message,
                                                fullWidth: true,
                                              },
                                            }}
                                          />
                                        </LocalizationProvider>
                                      );
                                    }
                                    return (
                                      <TextField
                                        {...field}
                                        placeholder={
                                          itemLabelName === "Cash"
                                            ? "Amount"
                                            : itemLabelName
                                        }
                                        error={fieldState.error ? true : false}
                                        helperText={fieldState.error?.message}
                                        sx={{
                                          width: "100%",
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      marginTop={2}
                    >
                      <Typography variant="textfieldLabel">
                        {`${
                          type === "CASH" ? "Cash" : "Check"
                        } Receipt Documents`}
                      </Typography>
                      <Button
                        variant="button-secondary"
                        disabled={data ? false : true}
                        sx={{
                          width: 150,
                        }}
                        startIcon={
                          saveLoading && (
                            <CircularProgress
                              size={20}
                              sx={(theme) => ({
                                color: theme.palette.common.white,
                              })}
                            />
                          )
                        }
                        onClick={toggleUploadDoc}
                      >
                        Upload
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider
                  sx={(theme) => ({
                    marginY: theme.spacing(3),
                  })}
                />
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    variant="button-primary"
                    sx={{
                      width: 150,
                    }}
                    startIcon={
                      saveLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={
                      saveLoading
                        ? () => {}
                        : handleSubmit(onSubmitSave, onError)
                    }
                  >
                    {data ? "Update" : "Save"}
                  </Button>
                  <Button
                    variant="button-secondary"
                    disabled={data?.id ? false : true}
                    sx={{
                      width: 150,
                    }}
                    startIcon={
                      docIsLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={() =>
                      getDoc({
                        id: data?.id as number,
                        type,
                      })
                    }
                  >
                    Print
                  </Button>
                </Stack>
              </FormProvider>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateVoucher;
