import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  FormProvider,
  Controller,
  useWatch,
  useFieldArray,
  SubmitHandler,
} from "react-hook-form";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import { DatePicker } from "@mui/x-date-pickers";

import CardHeader from "../../common/CardHeader";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { LoaderWithText } from "../../common/Loaders";
import { thisFieldRequired } from "../../../utils/constants";
import { useLazyGetPaymentsListConfigQuery } from "../../../service/slice/system-configuration/paymentsTypesSlice";
import {
  useLazyGetDiscountListConfigQuery,
  useLazyGetPromoListConfigQuery,
} from "../../../service/slice/system-configuration/discountPromoSlice";
import useFileView from "../../../hooks/useFileView";
import {
  useAddSchedulePaymentMutation,
  useGetSchedulePaymentQuery,
  useLazyGetServiceReceiptQuery,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
import FileViewModal from "../../common/FileViewModal";
import PaymentUpload from "../../client-management/buyers/active-buyer-form/payment/PaymentUpload";
import IconButtonMdi from "../../common/IconButtonMdi";
import { getValueFromPercent } from "../../../utils/helpers/mathHelper";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";

interface Props {
  form: any;
  serviceType: string;
}

const Payment = ({ serviceType, form }: Props) => {
  const theme = useTheme();
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [printArLoading, setPrintArLoading] = useState<boolean>(false);
  const [printOrLoading, setPrintOrLoading] = useState<boolean>(false);
  const [viewDoc, handelToggleViewDoc] = useFileView();
  const [addData, setAddData] = useState<any>();

  // const isPast = moment().isAfter(moment(form.watch("date")), "day");
  const isPast = false;

  const [
    getPaymentListTrigger,
    {
      data: paymentListData,
      isLoading: paymentListIsLoading,
      error: paymentListError,
    },
  ] = useLazyGetPaymentsListConfigQuery();

  const [
    getDiscountListTrigger,
    {
      data: discountListData,
      isLoading: discountListIsLoading,
      error: discountListError,
    },
  ] = useLazyGetDiscountListConfigQuery();

  const [
    getPromoListTrigger,
    {
      data: promoListData,
      isLoading: promoListIsLoading,
      error: promoListError,
    },
  ] = useLazyGetPromoListConfigQuery();

  const [
    addMutate,
    {
      error: addError,
      isLoading: addIsLoading,
      reset: addReset,
      isSuccess: addIsSuccess,
      data: addScheduleData,
    },
  ] = useAddSchedulePaymentMutation();

  const [
    getReceiptTrigger,
    {
      data: receiptData,
      isLoading: receiptIsLoading,
      isSuccess: receiptIsSuccess,
      error: receiptError,
    },
  ] = useLazyGetServiceReceiptQuery();

  const {
    data: paymentData,
    isLoading: paymentDataIsLoading,
    error: paymentDataError,
    isFetching: paymentDataIsFetching,
  } = useGetSchedulePaymentQuery(form.watch("id") || addData?.data?.id);

  const loading =
    paymentListIsLoading ||
    discountListIsLoading ||
    promoListIsLoading ||
    paymentDataIsLoading ||
    paymentDataIsFetching;

  const saveLoading = addIsLoading;

  const rateTypeOptions = [
    {
      label: "Regular Rate",
      value: "REGULAR",
    },
    {
      label: "Holiday Rate",
      value: "HOLIDAY",
    },
    {
      label: "Senior/PWD Rate",
      value: "SP",
    },
    {
      label: "Holiday Rate (Senior/PWD)",
      value: "SP-HOLIDAY",
    },
  ];

  const paymentTypeOptions = useMemo(() => {
    return (
      paymentListData?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [paymentListData?.data]);

  const discountOptions = useMemo(() => {
    return (
      discountListData?.data?.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.type}|${item.value}`,
      })) ?? []
    );
  }, [discountListData?.data]);

  const promoOptions = useMemo(() => {
    return (
      promoListData?.data?.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.type}|${item.value}`,
      })) ?? []
    );
  }, [promoListData?.data]);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    getValues,
    setError,
    clearErrors,
  } = form;

  const fullPaymentWatch = useWatch({
    control,
    name: "fullPaymentAmount",
  });

  const scheduledDownpaymentWatch = useWatch({
    control,
    name: "downPaymentAmount",
  });

  const priceWatch = useWatch({
    control,
    name: "serviceDetails.price",
  });

  const discountWatch = useWatch({
    control,
    name: "serviceDetails.discounts",
  });

  const promoWatch = useWatch({
    control,
    name: "serviceDetails.promo",
  });

  const {
    fields: discountFields,
    append: discountAppend,
    remove: discountRemove,
    replace: discountReplace,
  } = useFieldArray({
    control,
    name: "serviceDetails.discounts",
  });

  const {
    fields: promoFields,
    append: promoAppend,
    remove: promoRemove,
    replace: promoReplace,
  } = useFieldArray({
    control,
    name: "serviceDetails.promo",
  });

  const paymentTypeWatch = useWatch({
    name: "paymentType",
    control,
  });

  const paymentTermsWatch = useWatch({
    name: "paymentTerms",
    control,
  });

  const rateTypeWatch = useWatch({
    name: "rateType",
    control,
  });

  const toggleUploadDoc = () => setOpenUpload((prev) => !prev);

  const onSubmit: SubmitHandler<any> = (dataFields) => {
    const errorCount = checkErrors();

    if (errorCount.length > 0) {
      return;
    }

    const {
      paymentTerms,
      rateType,
      date,
      paymentType,
      paymentTypeAmount,
      serviceDetails,
      service,
      fullPaymentAmount,
      downPaymentAmount,
      paymentDate,
      orNumber,
    } = dataFields;
    const { discounts, promo, price } = serviceDetails;

    let finalPromo = promo[0].value.value
      ? promo.map((item: any) => {
          const splitValue = item.value.value!.split("|");
          return {
            type: "PROMO",
            pdId: Number(splitValue[0]),
            amount: getValueFromPercent(splitValue[2], price),
          };
        })
      : [];

    let finalDiscount = discounts[0].value.value
      ? discounts.map((item: any) => {
          const splitValue = item.value.value!.split("|");

          return {
            type: "DISCOUNT",
            pdId: Number(splitValue[0]),
            amount: getValueFromPercent(splitValue[2], price),
          };
        })
      : [];

    let finalPaymentFields = paymentListData?.data?.find(
      (item) => item.id === Number(paymentType.value)
    );

    addMutate({
      queryParams: serviceType.toUpperCase(),
      bodyParams: {
        termId: form.watch("id"),
        serviceId: service.id,
        term: paymentTerms,
        rateType: rateType.value,
        amount:
          paymentTerms === "FULL-PAYMENT"
            ? fullPaymentAmount
            : downPaymentAmount,
        date: moment(date).format("YYYY-MM-DD"),
        payment: {
          paymentId: Number(paymentType.value),
          datePosted: paymentDate
            ? moment(paymentDate).format("YYYY-MM-DD")
            : paymentDate,
          orNumber: orNumber,
          amount: Number(paymentTypeAmount),
          paymentFields: finalPaymentFields
            ? finalPaymentFields.paymentFields.map((item) => ({
                fieldId: item.id,
                // value:
                //   paymentType.label === "Check"
                //     ? moment(date).format("YYYY-MM-DD")
                //     : paymentTypeAmount,
                value: paymentTypeAmount,
              }))
            : [],
        },
        promoDiscount: [...finalDiscount, ...finalPromo],
      },
    });
  };

  const onError = () => {
    // checkErrors();
  };

  const checkErrors = (): number[] => {
    const dataFields = getValues();
    let errors: number[] = [];

    if (dataFields.paymentType.value === null) {
      setError("paymentType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    return errors;
  };

  const showDiscountPromo = (val: string): string => {
    const initVal = val.split("|");

    if (initVal[1] === "AMOUNT") {
      return `less ${initVal[2]}`;
    } else if (initVal[1] === "PERCENTAGE") {
      return `less ${initVal[2]}%`;
    }

    return "";
  };

  const handleAddRemoveFieldArray = (index: number | null, key: string) => {
    if (typeof index === "number") {
      if (key === "discount") {
        discountRemove(index);
      } else {
        promoRemove(index);
      }
    } else {
      if (key === "discount") {
        discountAppend({
          value: {
            label: null,
            value: null,
          },
        });
      } else {
        promoAppend({
          value: {
            label: null,
            value: null,
          },
        });
      }
    }
  };

  const handlePaymentTermsOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;

    setValue("fullPaymentAmount", value === "FULL-PAYMENT" ? priceWatch : "");
    setValue("downPaymentAmount", "");
    setValue("serviceDetails.payment", "");
    clearErrors(["fullPaymentAmount", "downPaymentAmount"]);
    setValue("paymentTerms", value);
  };

  if (paymentListError) {
    errorDisplayOrNavigate({
      error: paymentListError,
      toastId: "payment-list-config",
    });
  }

  if (discountListError) {
    errorDisplayOrNavigate({
      error: discountListError,
      toastId: "discount-list-config",
    });
  }

  if (promoListError) {
    errorDisplayOrNavigate({
      error: promoListError,
      toastId: "promo-list-config",
    });
  }

  if (paymentDataError) {
    errorDisplayOrNavigate({
      error: paymentDataError,
      toastId: "payment-data",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-payment",
    });

    addReset();
  }

  if (receiptError) {
    errorDisplayOrNavigate({
      error: receiptError,
      toastId: "receipt",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: `${form.watch("service").name} successfully paid`,
      toastId: "add-payment",
    });
    addReset();
    setAddData(addScheduleData);
    form.setValue("id", addScheduleData?.data?.servicePaymentTermId);
    form.setValue("paymentId", addScheduleData?.data?.paymentId);
    form.setValue(
      "servicePaymentTermId",
      addScheduleData?.data?.servicePaymentTermId
    );
  }

  useEffect(() => {
    if (
      (form.watch("service")?.id && form.watch("id")) ||
      (form.watch("service")?.id && form.watch("date"))
    ) {
      getDiscountListTrigger();
      getPromoListTrigger();
    }
    getPaymentListTrigger();
  }, [form.watch("id"), form.watch("service"), form.watch("date")]);

  useEffect(() => {
    if (receiptIsSuccess) {
      handelToggleViewDoc(true, receiptData?.data.link ?? "");
    }
  }, [receiptIsSuccess, receiptData?.data?.link]);

  useEffect(() => {
    if (paymentData?.data?.id) {
      console.log(paymentData.data);

      const {
        id,
        paymentId,
        term,
        totalPayment,
        promoDiscount,
        rateType,
        datePosted,
        orNumber,
      } = paymentData.data;

      setValue("id", id);
      setValue("servicePaymentTermId", id);
      setValue("paymentId", paymentId);
      setValue("paymentTerms", term);
      setValue("serviceDetails.payment", totalPayment.toString());
      setValue("paymentDate", datePosted ? new Date(datePosted) : null);
      setValue("orNumber", orNumber);
      if (term === "FULL-PAYMENT") {
        setValue("fullPaymentAmount", totalPayment.toString());
      } else if (term === "DOWNPAYMENT") {
        setValue("downPaymentAmount", totalPayment.toString());
      }

      if (promoDiscount.length > 0) {
        const filteredDiscounts = promoDiscount.filter(
          (item: any) => item.type === "DISCOUNT"
        );
        const filteredPromos = promoDiscount.filter(
          (item: any) => item.type === "PROMO"
        );

        setValue(
          "serviceDetails.discounts",
          filteredDiscounts.length > 0
            ? filteredDiscounts.map((item: any) => {
                const findRef = discountOptions.find(
                  (ref) => ref.label === item.name
                );

                return findRef
                  ? {
                      value: findRef,
                    }
                  : {
                      value: {
                        label: null,
                        value: null,
                      },
                    };
              })
            : [
                {
                  value: {
                    label: null,
                    value: null,
                  },
                },
              ]
        );

        setValue(
          "serviceDetails.promo",
          filteredPromos.length > 0
            ? filteredPromos.map((item: any) => {
                const findRef = promoOptions.find(
                  (ref) => ref.label === item.name
                );

                return findRef
                  ? {
                      value: findRef,
                    }
                  : {
                      value: {
                        label: null,
                        value: null,
                      },
                    };
              })
            : [
                {
                  value: {
                    label: null,
                    value: null,
                  },
                },
              ]
        );
      }
    }
  }, [paymentData]);

  useEffect(() => {
    if (form.watch("service")) {
      const price =
        rateTypeWatch.value === "REGULAR"
          ? form.watch("service")?.regular
          : rateTypeWatch.value === "HOLIDAY"
          ? form.watch("service")?.holiday
          : rateTypeWatch.value === "SP"
          ? form.watch("service")?.sp
          : rateTypeWatch.value === "SP-HOLIDAY"
          ? form.watch("service")?.spHoliday
          : "";
      setValue("serviceDetails.price", price ?? "");
      if (!form.watch("paymentId") && paymentTermsWatch === "FULL-PAYMENT") {
        setValue("fullPaymentAmount", price);
      }
      // paymentTermsWatch === "FULL-PAYMENT" &&
      //   setValue("fullPaymentAmount", price);

      // setValue(
      //   "serviceDetails.balance",
      //   price
      //     ? String(Number(price)).includes("-")
      //       ? "0"
      //       : String(Number(price))
      //     : ""
      // );
    }
  }, [rateTypeWatch, form.watch("service")]);

  useEffect(() => {
    const discountHasValue = discountWatch.find(
      (item: any) => item.value.value !== null
    );

    const promoHasValue = promoWatch.find(
      (item: any) => item.value.value !== null
    );

    let totalDiscountPromo: number[] = [];
    let paymentAmount: number = 0;

    if (discountHasValue) {
      discountWatch.forEach((item: any) => {
        if (item.value.value) {
          const splitValue = item.value.value.split("|");
          const discount =
            splitValue[1] === "PERCENTAGE"
              ? getValueFromPercent(splitValue[2], priceWatch)
              : Number(splitValue[2]);
          totalDiscountPromo.push(discount);
        }
      }, []);
    }

    if (promoHasValue) {
      promoWatch.forEach((item: any) => {
        if (item.value.value) {
          const splitValue = item.value.value.split("|");
          const promo =
            splitValue[1] === "PERCENTAGE"
              ? getValueFromPercent(splitValue[2], priceWatch)
              : Number(splitValue[2]);
          totalDiscountPromo.push(promo);
        }
      });
    }

    const finalDiscountPromo =
      totalDiscountPromo.length > 0
        ? totalDiscountPromo.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          )
        : 0;

    if (paymentTermsWatch === "FULL-PAYMENT" || !_.isEmpty(fullPaymentWatch)) {
      paymentAmount = Number(fullPaymentWatch);
      setValue("serviceDetails.payment", fullPaymentWatch);
    } else if (
      paymentTermsWatch === "DOWNPAYMENT" ||
      !_.isEmpty(scheduledDownpaymentWatch)
    ) {
      paymentAmount = Number(scheduledDownpaymentWatch);
      setValue("serviceDetails.payment", scheduledDownpaymentWatch);
    }

    const paymentAmountFinal = _.isNaN(paymentAmount) ? 0 : paymentAmount;
    setValue(
      "serviceDetails.balance",
      String(
        Number(priceWatch) - (finalDiscountPromo + paymentAmountFinal)
      ).includes("-")
        ? "0"
        : String(Number(priceWatch) - (finalDiscountPromo + paymentAmountFinal))
    );
  }, [
    paymentTermsWatch,
    promoWatch,
    discountWatch,
    fullPaymentWatch,
    scheduledDownpaymentWatch,
    priceWatch,
  ]);

  useEffect(() => {
    clearErrors(["chequeNumber", "paymentTypeAmount", "bankName", "date"]);
  }, [paymentTypeWatch]);

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting other information.." />
    </Box>
  ) : (
    <FormProvider {...form}>
      {form.watch("id") ? (
        <Box marginBottom={2} paddingY={1}>
          <CardHeader title="Payment" />
          <Box
            // bgcolor={
            //   paymentData?.data?.id ? theme.palette.grey[100] : "transparent"
            // }
            padding={"10px"}
            borderRadius={"5px"}
          >
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <div>
                    <Typography variant="textfieldLabel">
                      Payment Terms
                    </Typography>
                    <Controller
                      name="paymentTerms"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please select a payment term.",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <RadioGroup
                            {...field}
                            value={paymentTermsWatch}
                            onChange={handlePaymentTermsOnChange}
                          >
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={3.6}>
                                <FormControlLabel
                                  sx={{
                                    label: {
                                      fontSize: ".9rem",
                                    },
                                  }}
                                  value="FULL-PAYMENT"
                                  label="Full payment"
                                  control={<Radio disabled={isPast} />}
                                />
                              </Grid>
                              <Grid item xs={4.2}>
                                <Controller
                                  name="fullPaymentAmount"
                                  rules={{
                                    required: {
                                      value:
                                        paymentTermsWatch === "FULL-PAYMENT",
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      disabled={
                                        paymentTermsWatch === "FULL-PAYMENT"
                                          ? false
                                          : true
                                      }
                                      InputProps={{
                                        readOnly: isPast,
                                      }}
                                      placeholder="Amount"
                                      error={fieldState.error ? true : false}
                                      helperText={fieldState.error?.message}
                                      sx={{
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={4.2} />
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              marginTop="1px"
                            >
                              <Grid item xs={3.6}>
                                <FormControlLabel
                                  sx={{
                                    label: {
                                      fontSize: ".9rem",
                                    },
                                  }}
                                  value="DOWNPAYMENT"
                                  label="Downpayment"
                                  control={<Radio disabled={isPast} />}
                                />
                              </Grid>
                              <Grid item xs={4.2}>
                                <Controller
                                  name="downPaymentAmount"
                                  control={control}
                                  rules={{
                                    required: {
                                      value:
                                        paymentTermsWatch === "DOWNPAYMENT",
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      disabled={
                                        paymentTermsWatch === "DOWNPAYMENT"
                                          ? false
                                          : true
                                      }
                                      InputProps={{
                                        readOnly: isPast,
                                      }}
                                      placeholder="Downpayment"
                                      error={fieldState.error ? true : false}
                                      helperText={fieldState.error?.message}
                                      sx={{
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                          {error && (
                            <FormHelperText error>
                              {error.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">Payment</Typography>
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Payment Type
                        </Typography>
                        <CustomReactSelectJs
                          control={control}
                          options={paymentTypeOptions}
                          name="paymentType"
                          placeholder="Payment Type"
                          isDisabled={isPast}
                          isRequired
                        />
                      </div>
                      {!_.isEmpty(paymentTypeWatch.value) && (
                        <>
                          <div>
                            <Typography variant="textfieldLabel">
                              Amount
                            </Typography>
                            <Controller
                              name="paymentTypeAmount"
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              control={control}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  InputProps={{
                                    readOnly: isPast,
                                  }}
                                  placeholder="Amount"
                                  error={fieldState.error ? true : false}
                                  helperText={fieldState.error?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </div>
                          {(paymentTypeWatch.label?.toUpperCase() === "CHECK" ||
                            paymentTypeWatch.label?.toUpperCase() ===
                              "CHEQUE") && (
                            <>
                              <div>
                                <Typography variant="textfieldLabel">
                                  Bank Name
                                </Typography>
                                <Controller
                                  name="bankName"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      InputProps={{
                                        readOnly: isPast,
                                      }}
                                      placeholder="Bank Name"
                                      error={fieldState.error ? true : false}
                                      helperText={fieldState.error?.message}
                                      sx={{
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div>
                                <Typography variant="textfieldLabel">
                                  Cheque Number
                                </Typography>
                                <Controller
                                  name="chequeNumber"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      InputProps={{
                                        readOnly: isPast,
                                      }}
                                      placeholder="Cheque Number"
                                      error={fieldState.error ? true : false}
                                      helperText={fieldState.error?.message}
                                      sx={{
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div>
                                <Typography variant="textfieldLabel">
                                  Date
                                </Typography>
                                <Controller
                                  name="chequeDate"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  disabled={isPast}
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <DatePicker
                                        {...field}
                                        slotProps={{
                                          textField: {
                                            variant: "outlined",
                                            error: fieldState.error?.message
                                              ? true
                                              : false,
                                            helperText:
                                              fieldState.error?.message,
                                            fullWidth: true,
                                          },
                                        }}
                                      />
                                    </LocalizationProvider>
                                  )}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {/* <div>
                      <Typography variant="textfieldLabel">Receipt</Typography>
                      <CustomReactSelectJs
                        control={control}
                        options={receiptOpt}
                        name="receipt"
                        placeholder="Receipt"
                      />
                    </div> */}
                    </Stack>
                  </div>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="textfieldLabel">
                      Payment Documents
                    </Typography>
                    <Button
                      variant="button-secondary"
                      // disabled={serviceId ? false : true}
                      sx={{
                        width: 150,
                      }}
                      startIcon={
                        saveLoading && (
                          <CircularProgress
                            size={20}
                            sx={(theme) => ({
                              color: theme.palette.common.white,
                            })}
                          />
                        )
                      }
                      onClick={toggleUploadDoc}
                    >
                      Upload
                    </Button>
                  </Stack>
                  <div>
                    <Typography variant="textfieldLabel">
                      Payment Date
                    </Typography>
                    <Controller
                      name="paymentDate"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: thisFieldRequired,
                      //   },
                      // }}
                      control={control}
                      render={({ field, fieldState }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              {...field}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  error: fieldState.error?.message
                                    ? true
                                    : false,
                                  helperText: fieldState.error?.message,
                                  fullWidth: true,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        );
                      }}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">OR Number</Typography>
                    <Controller
                      name="orNumber"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: thisFieldRequired,
                      //   },
                      // }}
                      control={control}
                      render={({ field, fieldState }) => {
                        return (
                          <TextField
                            {...field}
                            placeholder="OR Number"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <div>
                    <Typography variant="textfieldLabel">Rate Type</Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={rateTypeOptions}
                      name="rateType"
                      placeholder="Rate Type"
                      isDisabled={isPast}
                    />
                  </div>
                  <Box
                    sx={(theme) => ({
                      borderRadius: 1,
                      border: `2px solid ${theme.palette.primary.main}`,
                      padding: theme.spacing(2),
                      background: theme.palette.grey[100],
                      marginBottom: 4,
                    })}
                  >
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">Price</Typography>
                        <Controller
                          name="serviceDetails.price"
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              InputProps={{
                                readOnly: true,
                              }}
                              placeholder="Price"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Payment
                        </Typography>
                        <Controller
                          name="serviceDetails.payment"
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: thisFieldRequired,
                          //   },
                          // }}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              InputProps={{
                                readOnly: true,
                              }}
                              placeholder="Payment"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Discount
                        </Typography>
                        {discountFields.map((item, key) => (
                          <Stack
                            direction="row"
                            spacing={1}
                            key={key}
                            paddingTop={key === 0 ? 1 : 2}
                          >
                            <IconButtonMdi
                              mdiIcon={
                                key + 1 === discountWatch.length
                                  ? mdiPlusCircleOutline
                                  : mdiMinusCircleOutline
                              }
                              iconColor={
                                key + 1 === discountWatch.length
                                  ? theme.palette.info.main
                                  : theme.palette.error.main
                              }
                              onClick={
                                isPast
                                  ? () => {}
                                  : () =>
                                      handleAddRemoveFieldArray(
                                        key + 1 === discountWatch.length
                                          ? null
                                          : key,
                                        "discount"
                                      )
                              }
                            />
                            <Box width="100%">
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs={8}>
                                  <CustomReactSelectJs
                                    name={`serviceDetails.discounts.${key}.value`}
                                    control={control}
                                    options={discountOptions}
                                    placeholder="Discount"
                                    // @ts-ignore
                                    value={discountWatch[key]?.value}
                                    isDisabled={isPast}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="textfieldLabel">
                                    {showDiscountPromo(
                                      discountWatch[key]?.value?.value ?? ""
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Stack>
                        ))}
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">Promo</Typography>
                        {promoFields.map((item, key) => (
                          <Stack
                            direction="row"
                            spacing={1}
                            key={key}
                            paddingTop={key === 0 ? 1 : 2}
                          >
                            <IconButtonMdi
                              mdiIcon={
                                key + 1 === promoWatch.length
                                  ? mdiPlusCircleOutline
                                  : mdiMinusCircleOutline
                              }
                              iconColor={
                                key + 1 === promoWatch.length
                                  ? theme.palette.info.main
                                  : theme.palette.error.main
                              }
                              onClick={
                                isPast
                                  ? () => {}
                                  : () =>
                                      handleAddRemoveFieldArray(
                                        key + 1 === promoWatch.length
                                          ? null
                                          : key,
                                        "promo"
                                      )
                              }
                            />
                            <Box width="100%">
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs={8}>
                                  <CustomReactSelectJs
                                    name={`serviceDetails.promo.${key}.value`}
                                    control={control}
                                    options={promoOptions}
                                    placeholder="Promo"
                                    // @ts-ignore
                                    value={promoWatch[key]?.value}
                                    isDisabled={isPast}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="textfieldLabel">
                                    {showDiscountPromo(
                                      promoWatch[key]?.value?.value ?? ""
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Stack>
                        ))}
                      </div>
                      <Divider />
                      <div>
                        <Typography variant="textfieldLabel">
                          Balance
                        </Typography>
                        <Controller
                          name="serviceDetails.balance"
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              InputProps={{
                                readOnly: true,
                              }}
                              placeholder="Balance"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2} justifyContent="center" mt={6}>
              {!form.watch("paymentId") && !isPast && (
                <Button
                  variant="button-primary"
                  // disabled={scheduleId ? false : true}
                  sx={{
                    marginTop: 4,
                    width: 150,
                  }}
                  startIcon={
                    saveLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  Pay
                </Button>
              )}
              {form.watch("paymentId") && (
                <>
                  <Button
                    variant="button-primary"
                    // disabled={scheduleId ? false : true}
                    sx={{
                      marginTop: 4,
                      width: 150,
                    }}
                    startIcon={
                      printArLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={async () => {
                      setPrintArLoading(true);
                      await getReceiptTrigger({
                        id:
                          (addData?.data?.id as number) ||
                          paymentData?.data?.paymentId,
                        receiptType: "AR",
                        paymentType: "SERVICE",
                      });
                      setPrintArLoading(false);
                    }}
                  >
                    Print AR
                  </Button>
                  <Button
                    variant="button-primary"
                    // disabled={scheduleId ? false : true}
                    sx={{
                      marginTop: 4,
                      width: 150,
                    }}
                    startIcon={
                      printOrLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={async () => {
                      setPrintOrLoading(true);
                      await getReceiptTrigger({
                        id:
                          (addData?.data?.id as number) ||
                          paymentData?.data?.paymentId,
                        receiptType: "OR",
                        paymentType: "SERVICE",
                      });
                      setPrintOrLoading(false);
                    }}
                  >
                    Print OR
                  </Button>
                </>
              )}
            </Stack>
            {openUpload && (
              <PaymentUpload
                open={openUpload}
                title={`Payment Documents`}
                buyerPaymentId={
                  (paymentData?.data?.paymentId || addData?.data?.id) ?? null
                }
                onClose={toggleUploadDoc}
              />
            )}
            <FileViewModal
              open={viewDoc.open}
              path={viewDoc.path}
              title={viewDoc.path.substring(
                viewDoc.path.lastIndexOf("/") + 1,
                viewDoc.path.length
              )}
              onClose={() => handelToggleViewDoc(false, "")}
            />
          </Box>
        </Box>
      ) : (
        <Alert severity="warning">
          Please select a schedule to fill up this form.
        </Alert>
      )}
    </FormProvider>
  );
};
export default Payment;
