import Grid from "@mui/material/Grid";
import RequirementsUploader from "./RequirementsUploader";
import Alert from "@mui/material/Alert";

interface Props {
  scheduleForm: any;
  serviceType: string;
}

const Requirements = ({ serviceType, scheduleForm }: Props) => {
  const serviceRequiredDocuments = scheduleForm?.watch(
    "serviceRequiredDocuments"
  );
  return (
    <>
      {scheduleForm.watch("service")?.id && scheduleForm.watch("id") ? (
        serviceRequiredDocuments?.length === 0 ? (
          <Alert severity="info">No documents needed.</Alert>
        ) : (
          <Grid container marginTop={4} spacing={3}>
            {serviceRequiredDocuments.map((document: any, index: any) => (
              <Grid item xs={12} key={index}>
                <RequirementsUploader
                  title={`${document.name}`}
                  uploadTitle={`Upload ${document.name}`}
                  type={document}
                  scheduleForm={scheduleForm}
                />
              </Grid>
            ))}
          </Grid>
        )
      ) : (
        <Alert severity="warning">
          Please select a schedule to fill up this form.
        </Alert>
      )}
    </>
  );
};

export default Requirements;
