import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  FormProvider,
  Controller,
  useWatch,
  useFieldArray,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import { DatePicker } from "@mui/x-date-pickers";

import CardHeader from "../../common/CardHeader";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { LoaderWithText } from "../../common/Loaders";
import { thisFieldRequired } from "../../../utils/constants";
import { useLazyGetPaymentsListConfigQuery } from "../../../service/slice/system-configuration/paymentsTypesSlice";
import {
  useLazyGetDiscountListConfigQuery,
  useLazyGetPromoListConfigQuery,
} from "../../../service/slice/system-configuration/discountPromoSlice";
import useFileView from "../../../hooks/useFileView";
import {
  useAddScheduleInventoryMutation,
  useGetScheduleInventoryQuery,
  useLazyGetIntermentFormQuery,
  useLazyGetServiceGuidelineQuery,
  useLazyGetServiceReceiptQuery,
  useLazyGetWaiverQuery,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
import FileViewModal from "../../common/FileViewModal";
import PaymentUpload from "../../client-management/buyers/active-buyer-form/payment/PaymentUpload";
import IconButtonMdi from "../../common/IconButtonMdi";
import { getValueFromPercent } from "../../../utils/helpers/mathHelper";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import useTableParameters from "../../../hooks/useTableParameters";
import { useDownloadBuyerGuidelineQuery } from "../../../service/slice/client-management/buyer/buyerGuidelineSlice";

interface Props {
  scheduleForm: any;
  serviceType: string;
}

const headerLabels: string[] = [
  "",
  "Asset Name",
  "Quantity",
  "Amount/QTY",
  "Total",
];

const InventoryTemp = ({ serviceType, scheduleForm }: Props) => {
  const theme = useTheme();
  const serviceIncludedAssets = scheduleForm?.watch("serviceIncludedAssets");

  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [viewDoc, handelToggleViewDoc] = useFileView();

  const form = useForm<any>({
    defaultValues: {
      paymentId: undefined,
      price: "",
      paymentType: "",
      paymentTypeAmount: "",
      totalQuantity: "",
      totalAmount: "",
      bankName: "",
      chequeNumber: "",
      chequeDate: null,
      serviceDetails: {
        price: "",
        payment: "",
        discounts: [
          {
            value: {
              label: null,
              value: null,
            },
          },
        ],
        promo: [
          {
            value: {
              label: null,
              value: null,
            },
          },
        ],
        balance: "",
      },
      receipt: "",
      materials: [{ name: "", quantity: "", amount: "", total: "" }],
    },
  });

  const materialsArray = useFieldArray({
    control: form.control,
    name: "materials",
  });

  // const {
  //   data: listData,
  //   isLoading: listIsLoading,
  //   error: listError,
  //   isFetching: listIsFetching,
  // } = useGetPriceListQuery({
  //   type: serviceType.toUpperCase(),
  //   entries: entriesParam,
  //   page: pageParam - 1,
  //   search: searchParam ?? "",
  // });

  // const isPast = moment().isAfter(moment(form.watch("date")), "day");
  const isPast = false;

  const [
    getPaymentListTrigger,
    {
      data: paymentListData,
      isLoading: paymentListIsLoading,
      error: paymentListError,
    },
  ] = useLazyGetPaymentsListConfigQuery();

  const [
    getDiscountListTrigger,
    {
      data: discountListData,
      isLoading: discountListIsLoading,
      error: discountListError,
    },
  ] = useLazyGetDiscountListConfigQuery();

  const [
    getPromoListTrigger,
    {
      data: promoListData,
      isLoading: promoListIsLoading,
      error: promoListError,
    },
  ] = useLazyGetPromoListConfigQuery();

  const [
    addMutate,
    {
      error: addError,
      isLoading: addIsLoading,
      reset: addReset,
      isSuccess: addIsSuccess,
      data: addData,
    },
  ] = useAddScheduleInventoryMutation();

  const [
    getReceiptTrigger,
    {
      data: receiptData,
      isLoading: receiptIsLoading,
      isSuccess: receiptIsSuccess,
      error: receiptError,
    },
  ] = useLazyGetServiceReceiptQuery();

  const [
    getIntermentForm,
    {
      data: intermentFormData,
      isLoading: intermentFormIsLoading,
      isSuccess: intermentFormIsSuccess,
      error: intermentFormError,
    },
  ] = useLazyGetIntermentFormQuery();

  const [
    getWaiver,
    {
      data: waiverData,
      isLoading: waiverIsLoading,
      isSuccess: waiverIsSuccess,
      error: waiverError,
    },
  ] = useLazyGetWaiverQuery();

  const {
    data: inventoryData,
    isLoading: inventoryDataIsLoading,
    error: inventoryDataError,
    isFetching: inventoryDataIsFetching,
  } = useGetScheduleInventoryQuery(
    scheduleForm.watch("id") || addData?.data?.id
  );

  const loading =
    // userListIsLoading ||
    // buyerListIsLoading ||
    paymentListIsLoading ||
    discountListIsLoading ||
    promoListIsLoading ||
    inventoryDataIsLoading ||
    inventoryDataIsFetching;
  // listIsLoading ||
  // listIsFetching;

  const saveLoading = addIsLoading;

  // const list = listData?.data.rows ?? [];
  const list: any = [];
  // const totalData = listData?.data.count ?? 0;
  const totalData = 0;

  const paymentTypeOptions = useMemo(() => {
    return (
      paymentListData?.data.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [paymentListData?.data]);

  const discountOptions = useMemo(() => {
    return (
      discountListData?.data.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.type}|${item.value}`,
      })) ?? []
    );
  }, [discountListData?.data]);

  const promoOptions = useMemo(() => {
    return (
      promoListData?.data.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.type}|${item.value}`,
      })) ?? []
    );
  }, [promoListData?.data]);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    getValues,
    setError,
    clearErrors,
  } = form;

  const paymentTypeAmountWatch = useWatch({
    control,
    name: "paymentTypeAmount",
  });

  const priceWatch = useWatch({
    control,
    name: "serviceDetails.price",
  });

  const discountWatch = useWatch({
    control,
    name: "serviceDetails.discounts",
  });

  const promoWatch = useWatch({
    control,
    name: "serviceDetails.promo",
  });

  const {
    fields: discountFields,
    append: discountAppend,
    remove: discountRemove,
    replace: discountReplace,
  } = useFieldArray({
    control,
    name: "serviceDetails.discounts",
  });

  const {
    fields: promoFields,
    append: promoAppend,
    remove: promoRemove,
    replace: promoReplace,
  } = useFieldArray({
    control,
    name: "serviceDetails.promo",
  });

  const paymentTypeWatch = useWatch({
    name: "paymentType",
    control,
  });

  const toggleUploadDoc = () => setOpenUpload((prev) => !prev);

  const onSubmit: SubmitHandler<any> = (dataFields) => {
    const errorCount = checkErrors();

    if (errorCount.length > 0) {
      return;
    }

    const { paymentType, paymentTypeAmount, serviceDetails, materials } =
      dataFields;
    const { discounts, promo, price } = serviceDetails;
    const servicePaymentTermId = scheduleForm.watch("servicePaymentTermId");

    let finalPromo = promo[0].value.value
      ? promo.map((item: any) => {
          const splitValue = item.value.value!.split("|");
          return {
            type: "PROMO",
            pdId: Number(splitValue[0]),
            amount: getValueFromPercent(splitValue[2], price),
          };
        })
      : [];

    let finalDiscount = discounts[0].value.value
      ? discounts.map((item: any) => {
          const splitValue = item.value.value!.split("|");

          return {
            type: "DISCOUNT",
            pdId: Number(splitValue[0]),
            amount: getValueFromPercent(splitValue[2], price),
          };
        })
      : [];

    let finalPaymentFields = paymentListData?.data?.find(
      (item) => item.id === Number(paymentType.value)
    );

    addMutate({
      bodyParams: {
        servicePaymentTermId: servicePaymentTermId,
        amount: paymentTypeAmount,
        assets: materials.map((material: any) => ({
          name: material.name,
          amount: Number(material.amount),
          quantity: Number(material.quantity),
        })),
        payment: {
          paymentId: Number(paymentType.value),
          amount: Number(paymentTypeAmount),
          paymentFields: finalPaymentFields
            ? finalPaymentFields.paymentFields.map((item) => ({
                fieldId: item.id,
                // value:
                //   paymentType.label === "Check"
                //     ? moment(date).format("YYYY-MM-DD")
                //     : paymentTypeAmount,
                value: paymentTypeAmount,
              }))
            : [],
        },
        promoDiscount: [...finalDiscount, ...finalPromo],
      },
    });
  };

  const onError = () => {
    // checkErrors();
  };

  const checkErrors = (): number[] => {
    const dataFields = getValues();
    let errors: number[] = [];

    if (dataFields.paymentType.value === null) {
      setError("paymentType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    return errors;
  };

  const showDiscountPromo = (val: string): string => {
    const initVal = val.split("|");

    if (initVal[1] === "AMOUNT") {
      return `less ${initVal[2]}`;
    } else if (initVal[1] === "PERCENTAGE") {
      return `less ${initVal[2]}%`;
    }

    return "";
  };

  const handleAddRemoveFieldArray = (index: number | null, key: string) => {
    if (typeof index === "number") {
      if (key === "discount") {
        discountRemove(index);
      } else {
        promoRemove(index);
      }
    } else {
      if (key === "discount") {
        discountAppend({
          value: {
            label: null,
            value: null,
          },
        });
      } else {
        promoAppend({
          value: {
            label: null,
            value: null,
          },
        });
      }
    }
  };

  if (paymentListError) {
    errorDisplayOrNavigate({
      error: paymentListError,
      toastId: "payment-list-config",
    });
  }

  if (discountListError) {
    errorDisplayOrNavigate({
      error: discountListError,
      toastId: "discount-list-config",
    });
  }

  if (promoListError) {
    errorDisplayOrNavigate({
      error: promoListError,
      toastId: "promo-list-config",
    });
  }

  if (inventoryDataError) {
    errorDisplayOrNavigate({
      error: inventoryDataError,
      toastId: "payment-data",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-payment",
    });

    addReset();
  }

  if (receiptError) {
    errorDisplayOrNavigate({
      error: receiptError,
      toastId: "receipt",
    });
  }

  if (intermentFormError) {
    errorDisplayOrNavigate({
      error: intermentFormError,
      toastId: "rental-services-interment-form",
    });
  }

  if (waiverError) {
    errorDisplayOrNavigate({
      error: waiverError,
      toastId: "guideline",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: `${scheduleForm.watch("service").name} inventory successfully paid`,
      toastId: "add-payment",
    });
    addReset();
  }

  useEffect(() => {
    getPaymentListTrigger();
    getDiscountListTrigger();
    getPromoListTrigger();
  }, []);

  useEffect(() => {
    if (receiptIsSuccess) {
      handelToggleViewDoc(true, receiptData?.data.link ?? "");
    }
  }, [receiptIsSuccess, receiptData?.data?.link]);

  useEffect(() => {
    if (intermentFormIsSuccess && intermentFormData?.data?.link) {
      handelToggleViewDoc(
        true,
        intermentFormData?.data?.link ? intermentFormData?.data.link : ""
      );
    }
  }, [intermentFormIsSuccess, intermentFormData?.data?.link]);

  useEffect(() => {
    if (waiverIsSuccess && waiverData?.data?.link) {
      handelToggleViewDoc(
        true,
        waiverData?.data?.link ? waiverData?.data.link : ""
      );
    }
  }, [waiverIsSuccess, waiverData?.data?.link]);

  useEffect(() => {
    if (inventoryData?.data?.id) {
      const { id, amount, balance, payment, serviceAssetInventories } =
        inventoryData.data;

      const totalAmount = Number(amount) + Number(balance);
      const { paymentId, promoDiscount, totalPayment, paymentType } = payment;

      setValue("id", id);
      setValue("paymentId", paymentId);
      setValue("price", 0);
      setValue(
        "materials",
        serviceAssetInventories.map((material: any) => ({
          name: material.name,
          quantity: material.quantity,
          amount: material.amount,
          total: Number(material.quantity) * Number(material.amount),
        }))
      );
      setValue("totalAmount", totalAmount);
      setValue(
        "totalQuantity",
        serviceAssetInventories.reduce((total: any, obj: any, index: any) => {
          total += Number(obj.quantity);
          return total;
        }, 0)
      );
      setValue("paymentType", {
        label: paymentType.name,
        value: paymentType.id!.toString(),
      });
      setValue("paymentTypeAmount", totalPayment);
      setValue("serviceDetails.price", totalAmount);
      setValue("serviceDetails.payment", totalPayment.toString());
      setValue("serviceDetails.balance", balance.toString());

      if (promoDiscount.length > 0) {
        const filteredDiscounts = promoDiscount.filter(
          (item: any) => item.type === "DISCOUNT"
        );
        const filteredPromos = promoDiscount.filter(
          (item: any) => item.type === "PROMO"
        );

        setValue(
          "serviceDetails.discounts",
          filteredDiscounts.length > 0
            ? filteredDiscounts.map((item: any) => {
                const findRef = discountOptions.find(
                  (ref) => ref.label === item.name
                );

                return findRef
                  ? {
                      value: findRef,
                    }
                  : {
                      value: {
                        label: null,
                        value: null,
                      },
                    };
              })
            : [
                {
                  value: {
                    label: null,
                    value: null,
                  },
                },
              ]
        );

        setValue(
          "serviceDetails.promo",
          filteredPromos.length > 0
            ? filteredPromos.map((item: any) => {
                const findRef = promoOptions.find(
                  (ref) => ref.label === item.name
                );

                return findRef
                  ? {
                      value: findRef,
                    }
                  : {
                      value: {
                        label: null,
                        value: null,
                      },
                    };
              })
            : [
                {
                  value: {
                    label: null,
                    value: null,
                  },
                },
              ]
        );
      }
    } else {
      setValue(
        "materials",
        (serviceIncludedAssets?.length > 0
          ? serviceIncludedAssets
          : [{ name: "", quantity: null, amount: null, total: null }]
        )?.map((material: any) => ({
          id: material.id,
          name: material.name,
          quantity: material.quantity,
          amount: material.amount,
          total: Number(material.quantity) * Number(material.amount),
        }))
      );
      setValue(
        "totalAmount",
        serviceIncludedAssets?.reduce((total: any, obj: any, index: any) => {
          total += Number(obj.quantity) * Number(obj.amount);
          return total;
        }, 0)
      );
      setValue(
        "totalQuantity",
        serviceIncludedAssets?.reduce((total: any, obj: any, index: any) => {
          total += Number(obj.quantity);
          return total;
        }, 0)
      );
    }
  }, [inventoryData]);

  useEffect(() => {
    const discountHasValue = discountWatch.find(
      (item: any) => item.value.value !== null
    );

    const promoHasValue = promoWatch.find(
      (item: any) => item.value.value !== null
    );

    let totalDiscountPromo: number[] = [];
    let paymentAmount: number = 0;

    if (discountHasValue) {
      discountWatch.forEach((item: any) => {
        if (item.value.value) {
          const splitValue = item.value.value.split("|");
          const discount =
            splitValue[1] === "PERCENTAGE"
              ? getValueFromPercent(splitValue[2], priceWatch)
              : Number(splitValue[2]);
          totalDiscountPromo.push(discount);
        }
      }, []);
    }

    if (promoHasValue) {
      promoWatch.forEach((item: any) => {
        if (item.value.value) {
          const splitValue = item.value.value.split("|");
          const promo =
            splitValue[1] === "PERCENTAGE"
              ? getValueFromPercent(splitValue[2], priceWatch)
              : Number(splitValue[2]);
          totalDiscountPromo.push(promo);
        }
      });
    }

    const finalDiscountPromo =
      totalDiscountPromo.length > 0
        ? totalDiscountPromo.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          )
        : 0;

    if (!_.isEmpty(paymentTypeAmountWatch)) {
      paymentAmount = Number(paymentTypeAmountWatch);
      setValue("serviceDetails.payment", paymentTypeAmountWatch);
    }

    const paymentAmountFinal = _.isNaN(paymentAmount) ? 0 : paymentAmount;
    setValue(
      "serviceDetails.balance",
      String(
        Number(priceWatch) - (finalDiscountPromo + paymentAmountFinal)
      ).includes("-")
        ? "0"
        : String(Number(priceWatch) - (finalDiscountPromo + paymentAmountFinal))
    );
  }, [
    promoWatch,
    discountWatch,
    paymentTypeAmountWatch,
    form.watch("totalQuantity"),
    form.watch("totalAmount"),
  ]);

  useEffect(() => {
    clearErrors(["chequeNumber", "paymentTypeAmount", "bankName", "date"]);
  }, [paymentTypeWatch]);

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting other information.." />
    </Box>
  ) : (
    <FormProvider {...form}>
      {scheduleForm.watch("service")?.id && scheduleForm.watch("id") ? (
        <Box marginBottom={2} paddingY={1}>
          <CardHeader
            title={`${_.capitalize(
              serviceType
            )} Schedule - Additional materials`}
          />
          <Box paddingX={3} paddingTop={3} paddingBottom={2} mb={5}>
            <Typography variant="textfieldLabel" mb={3}>
              Date: {moment(scheduleForm.watch("date")).format("MMMM DD, YYYY")}{" "}
              {moment(scheduleForm.watch("time")).format("hh:mm A")}
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headerLabels.map((item, key) => (
                      <TableCell key={key} variant="head">
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materialsArray.fields &&
                    materialsArray.fields.length > 0 &&
                    materialsArray.fields.map((item: any, key: number) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:nth-of-type(even)": {
                            backgroundColor: "grey.100",

                            td: {
                              backgroundColor: "grey.100",
                            },
                          },
                          "&:nth-of-type(odd) td": {
                            backgroundColor: "common.white",
                          },
                        }}
                      >
                        <TableCell>
                          <IconButtonMdi
                            mdiIcon={
                              key + 1 === materialsArray.fields.length
                                ? mdiPlusCircleOutline
                                : mdiMinusCircleOutline
                            }
                            iconColor={
                              key + 1 === materialsArray.fields.length
                                ? theme.palette.info.main
                                : theme.palette.error.main
                            }
                            onClick={
                              key + 1 === materialsArray.fields.length
                                ? () =>
                                    materialsArray.append({
                                      name: "",
                                      quantity: "",
                                      amount: "",
                                      total: "",
                                    })
                                : () => {
                                    materialsArray.remove(key);
                                    setValue(
                                      "totalQuantity",
                                      form
                                        .watch(`materials`)
                                        .reduce(
                                          (
                                            total: any,
                                            obj: any,
                                            index: any
                                          ) => {
                                            total += Number(obj.quantity);
                                            return total;
                                          },
                                          0
                                        )
                                    );
                                    const total = form
                                      .watch(`materials`)
                                      .reduce(
                                        (total: any, obj: any, index: any) => {
                                          total += Number(obj.total);
                                          return total;
                                        },
                                        0
                                      );
                                    setValue("totalAmount", total);
                                    setValue("price", total);
                                    setValue("serviceDetails.price", total);
                                  }
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`materials.${key}.name`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                placeholder="Asset Name"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                                value={form.watch("materials")[key]?.name}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`materials.${key}.quantity`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Quantity"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  setValue(
                                    `materials.${key}.quantity`,
                                    e.target.value
                                  );
                                  clearErrors(`materials.${key}.quantity`);
                                  setValue(
                                    `materials.${key}.total`,
                                    Number(e.target.value) *
                                      Number(
                                        form.watch(`materials.${key}.amount`)
                                      )
                                  );
                                  setValue(
                                    "totalQuantity",
                                    form
                                      .watch(`materials`)
                                      .reduce(
                                        (total: any, obj: any, index: any) => {
                                          if (index === key) {
                                            total += Number(e.target.value);
                                          } else {
                                            total += Number(obj.quantity);
                                          }
                                          return total;
                                        },
                                        0
                                      )
                                  );
                                  const total = form
                                    .watch(`materials`)
                                    .reduce(
                                      (total: any, obj: any, index: any) => {
                                        if (index === key) {
                                          total +=
                                            Number(e.target.value) *
                                            Number(
                                              form.watch(
                                                `materials.${key}.amount`
                                              )
                                            );
                                        } else {
                                          total += Number(obj.total);
                                        }
                                        return total;
                                      },
                                      0
                                    );
                                  setValue("totalAmount", total);
                                  setValue("price", total);
                                  setValue("serviceDetails.price", total);
                                }}
                                value={form.watch("materials")[key]?.quantity}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`materials.${key}.amount`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Amount/QTY"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                onChange={(e) => {
                                  setValue(
                                    `materials.${key}.amount`,
                                    e.target.value
                                  );
                                  clearErrors(`materials.${key}.amount`);
                                  setValue(
                                    `materials.${key}.total`,
                                    Number(e.target.value) *
                                      Number(
                                        form.watch(`materials.${key}.quantity`)
                                      )
                                  );
                                  const total = form
                                    .watch(`materials`)
                                    .reduce(
                                      (total: any, obj: any, index: any) => {
                                        if (index === key) {
                                          total +=
                                            Number(e.target.value) *
                                            Number(
                                              form.watch(
                                                `materials.${key}.quantity`
                                              )
                                            );
                                        } else {
                                          total += Number(obj.total);
                                        }
                                        return total;
                                      },
                                      0
                                    );
                                  setValue("totalAmount", total);
                                  setValue("price", total);
                                  setValue("serviceDetails.price", total);
                                }}
                                sx={{
                                  width: "100%",
                                }}
                                value={form.watch("materials")[key]?.amount}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`materials.${key}.total`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                InputProps={{
                                  readOnly: true,
                                }}
                                placeholder="Total"
                                sx={{
                                  width: "100%",
                                }}
                                value={form.watch("materials")[key]?.total}
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <CardHeader title="Payment" />
          <Box
            // bgcolor={
            //   inventoryData?.data?.id ? theme.palette.grey[100] : "transparent"
            // }
            padding={"10px"}
            borderRadius={"5px"}
          >
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <div>
                    <Typography variant="textfieldLabel">
                      Total Quantity
                    </Typography>
                    <Controller
                      name="totalQuantity"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Amount"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">
                      Total Amount
                    </Typography>
                    <Controller
                      name="totalAmount"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Amount"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">Payment</Typography>
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Payment Type
                        </Typography>
                        <CustomReactSelectJs
                          control={control}
                          options={paymentTypeOptions}
                          name="paymentType"
                          placeholder="Payment Type"
                          isDisabled={isPast}
                          isRequired
                        />
                      </div>
                      {!_.isEmpty(paymentTypeWatch.value) && (
                        <>
                          <div>
                            <Typography variant="textfieldLabel">
                              Amount
                            </Typography>
                            <Controller
                              name="paymentTypeAmount"
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              control={control}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  InputProps={{
                                    readOnly: isPast,
                                  }}
                                  placeholder="Amount"
                                  error={fieldState.error ? true : false}
                                  helperText={fieldState.error?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </div>
                          {(paymentTypeWatch.label?.toUpperCase() === "CHECK" ||
                            paymentTypeWatch.label?.toUpperCase() ===
                              "CHEQUE") && (
                            <>
                              <div>
                                <Typography variant="textfieldLabel">
                                  Bank Name
                                </Typography>
                                <Controller
                                  name="bankName"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      InputProps={{
                                        readOnly: isPast,
                                      }}
                                      placeholder="Bank Name"
                                      error={fieldState.error ? true : false}
                                      helperText={fieldState.error?.message}
                                      sx={{
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div>
                                <Typography variant="textfieldLabel">
                                  Cheque Number
                                </Typography>
                                <Controller
                                  name="chequeNumber"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      InputProps={{
                                        readOnly: isPast,
                                      }}
                                      placeholder="Cheque Number"
                                      error={fieldState.error ? true : false}
                                      helperText={fieldState.error?.message}
                                      sx={{
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div>
                                <Typography variant="textfieldLabel">
                                  Date
                                </Typography>
                                <Controller
                                  name="chequeDate"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  disabled={isPast}
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <DatePicker
                                        {...field}
                                        slotProps={{
                                          textField: {
                                            variant: "outlined",
                                            error: fieldState.error?.message
                                              ? true
                                              : false,
                                            helperText:
                                              fieldState.error?.message,
                                            fullWidth: true,
                                          },
                                        }}
                                      />
                                    </LocalizationProvider>
                                  )}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {/* <div>
                      <Typography variant="textfieldLabel">Receipt</Typography>
                      <CustomReactSelectJs
                        control={control}
                        options={receiptOpt}
                        name="receipt"
                        placeholder="Receipt"
                      />
                    </div> */}
                    </Stack>
                  </div>
                  {/* <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="textfieldLabel">
                      Payment Documents
                    </Typography>
                    <Button
                      variant="button-secondary"
                      // disabled={serviceId ? false : true}
                      sx={{
                        width: 150,
                      }}
                      startIcon={
                        saveLoading && (
                          <CircularProgress
                            size={20}
                            sx={(theme) => ({
                              color: theme.palette.common.white,
                            })}
                          />
                        )
                      }
                      onClick={toggleUploadDoc}
                    >
                      Upload
                    </Button>
                  </Stack> */}
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box
                  sx={(theme) => ({
                    borderRadius: 1,
                    border: `2px solid ${theme.palette.primary.main}`,
                    padding: theme.spacing(2),
                    background: theme.palette.grey[100],
                    marginBottom: 4,
                  })}
                >
                  <Stack spacing={2}>
                    <div>
                      <Typography variant="textfieldLabel">Price</Typography>
                      <Controller
                        name="serviceDetails.price"
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              readOnly: true,
                            }}
                            placeholder="Price"
                            // error={fieldState.error ? true : false}
                            // helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Typography variant="textfieldLabel">Payment</Typography>
                      <Controller
                        name="serviceDetails.payment"
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              readOnly: true,
                            }}
                            placeholder="Payment"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Typography variant="textfieldLabel">Discount</Typography>
                      {discountFields.map((item, key) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          key={key}
                          paddingTop={key === 0 ? 1 : 2}
                        >
                          <IconButtonMdi
                            mdiIcon={
                              key + 1 === discountWatch.length
                                ? mdiPlusCircleOutline
                                : mdiMinusCircleOutline
                            }
                            iconColor={
                              key + 1 === discountWatch.length
                                ? theme.palette.info.main
                                : theme.palette.error.main
                            }
                            onClick={
                              isPast
                                ? () => {}
                                : () =>
                                    handleAddRemoveFieldArray(
                                      key + 1 === discountWatch.length
                                        ? null
                                        : key,
                                      "discount"
                                    )
                            }
                          />
                          <Box width="100%">
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs={8}>
                                <CustomReactSelectJs
                                  name={`serviceDetails.discounts.${key}.value`}
                                  control={control}
                                  options={discountOptions}
                                  placeholder="Discount"
                                  // @ts-ignore
                                  value={discountWatch[key]?.value}
                                  isDisabled={isPast}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="textfieldLabel">
                                  {showDiscountPromo(
                                    discountWatch[key]?.value?.value ?? ""
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Stack>
                      ))}
                    </div>
                    <div>
                      <Typography variant="textfieldLabel">Promo</Typography>
                      {promoFields.map((item, key) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          key={key}
                          paddingTop={key === 0 ? 1 : 2}
                        >
                          <IconButtonMdi
                            mdiIcon={
                              key + 1 === promoWatch.length
                                ? mdiPlusCircleOutline
                                : mdiMinusCircleOutline
                            }
                            iconColor={
                              key + 1 === promoWatch.length
                                ? theme.palette.info.main
                                : theme.palette.error.main
                            }
                            onClick={
                              isPast
                                ? () => {}
                                : () =>
                                    handleAddRemoveFieldArray(
                                      key + 1 === promoWatch.length
                                        ? null
                                        : key,
                                      "promo"
                                    )
                            }
                          />
                          <Box width="100%">
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs={8}>
                                <CustomReactSelectJs
                                  name={`serviceDetails.promo.${key}.value`}
                                  control={control}
                                  options={promoOptions}
                                  placeholder="Promo"
                                  // @ts-ignore
                                  value={promoWatch[key]?.value}
                                  isDisabled={isPast}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="textfieldLabel">
                                  {showDiscountPromo(
                                    promoWatch[key]?.value?.value ?? ""
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Stack>
                      ))}
                    </div>
                    <Divider />
                    <div>
                      <Typography variant="textfieldLabel">Balance</Typography>
                      <Controller
                        name="serviceDetails.balance"
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              readOnly: true,
                            }}
                            placeholder="Balance"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </div>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2} justifyContent="center" mt={6}>
              {!inventoryData?.data?.id && !isPast && (
                <Button
                  variant="button-primary"
                  // disabled={scheduleId ? false : true}
                  sx={{
                    marginTop: 4,
                    width: 150,
                  }}
                  startIcon={
                    saveLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  Pay
                </Button>
              )}
              {form.watch("paymentId") && (
                <>
                  <Button
                    variant="button-primary"
                    // disabled={scheduleId ? false : true}
                    sx={{
                      marginTop: 4,
                      width: 150,
                    }}
                    startIcon={
                      receiptIsLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={() => {
                      getReceiptTrigger({
                        id:
                          (addData?.data?.id as number) ||
                          inventoryData?.data?.payment?.paymentId,
                        receiptType: "AR",
                        paymentType: "SERVICE-ASSET",
                      });
                    }}
                  >
                    Print AR
                  </Button>
                  <Button
                    variant="button-primary"
                    // disabled={scheduleId ? false : true}
                    sx={{
                      marginTop: 4,
                      width: 210,
                    }}
                    startIcon={
                      intermentFormIsLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={() => {
                      if (intermentFormData?.data?.link) {
                        handelToggleViewDoc(
                          true,
                          intermentFormData?.data?.link
                            ? intermentFormData?.data.link
                            : ""
                        );
                      } else {
                        getIntermentForm(scheduleForm.watch("id"));
                      }
                    }}
                  >
                    {_.capitalize(serviceType)} Form
                  </Button>
                  {serviceType?.toLowerCase() === "exhumation" && (
                    <Button
                      variant="button-primary"
                      // disabled={scheduleId ? false : true}
                      sx={{
                        marginTop: 4,
                        width: 150,
                      }}
                      startIcon={
                        waiverIsLoading && (
                          <CircularProgress
                            size={20}
                            sx={(theme) => ({
                              color: theme.palette.common.white,
                            })}
                          />
                        )
                      }
                      onClick={() => {
                        getWaiver(scheduleForm.watch("id"));
                      }}
                    >
                      Waiver
                    </Button>
                  )}
                </>
              )}
            </Stack>
            <PaymentUpload
              open={openUpload}
              title={`Payment Documents`}
              buyerPaymentId={inventoryData?.data?.payment?.paymentId ?? null}
              onClose={toggleUploadDoc}
            />
            <FileViewModal
              open={viewDoc.open}
              path={viewDoc.path}
              title={viewDoc.path.substring(
                viewDoc.path.lastIndexOf("/") + 1,
                viewDoc.path.length
              )}
              onClose={() => handelToggleViewDoc(false, "")}
            />
          </Box>
        </Box>
      ) : (
        <Alert severity="warning">
          Please select a schedule to fill up this form.
        </Alert>
      )}
    </FormProvider>
  );
};
export default InventoryTemp;
