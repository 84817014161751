import { SerializedError } from "@reduxjs/toolkit";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  getFromStorage,
  saveToStorage,
  removeFromStorage,
} from "../utils/helpers/storageHelper";
import {
  gg_menu_key,
  gg_user_cred_key,
  refresh_token_key,
  token_key,
} from "../utils/constants";
import { loginRoute } from "../routes/routeKeys";

export interface GeneratedLinkModel {
  success: boolean;
  msg: string;
  data: {
    link: string;
  };
}

export interface UploadDocsDtoModel {
  success: string;
  data: {
    format?: string;
    name: string;
    link: string;
  };
  msg: string;
}

export interface ErrorResponseModel {
  data: {
    msg: string;
    data: {
      type: string;
      value: string;
      location: string;
      msg: string;
      path: string;
    }[];
  };
  status: number;
  originalStatus: number;
}

export const getApiErrorStatus = (
  error: FetchBaseQueryError | SerializedError | undefined
):
  | number
  | "FETCH_ERROR"
  | "PARSING_ERROR"
  | "TIMEOUT_ERROR"
  | "CUSTOM_ERROR"
  | null => {
  if (error && "status" in error) {
    return error.status;
  }

  return null;
};

export const errorHandler = (response: ErrorResponseModel) => {
  const errorStatus: number =
    typeof response.status === "string"
      ? response.originalStatus
      : response.status;
  switch (errorStatus) {
    case 401:
      return { data: "Unathorized!", status: response.status };
    case 404:
      return { data: "Page Not Found!", status: response.status };
    case 500:
      return { data: "Internal Error!", status: response.status };
    case 413:
      return { data: "File/Image size is too large", status: response.status };
    case 422:
      return {
        data:
          response.data.data.length > 0
            ? response.data.data[0].msg
            : response.data.msg,
        status: response.status,
      };
    default:
      return {
        data: response?.data?.msg ?? "Something went wrong.",
        status: response.status,
      };
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_ENDPOINT,
  prepareHeaders: (headers) => {
    const token = getFromStorage<string>(token_key);

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const token = getFromStorage<string>("refreshToken");
    const refreshResult = await baseQuery(
      { url: "/refresh-token", method: "POST", body: { refreshToken: token } },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      const dataRes = refreshResult.data as any;
      saveToStorage(token_key, dataRes.data.token);
      saveToStorage(refresh_token_key, dataRes.data.refreshToken);
      result = await baseQuery(args, api, extraOptions);
    } else {
      removeFromStorage(gg_menu_key);
      removeFromStorage(gg_user_cred_key);
      removeFromStorage(token_key);
      removeFromStorage(refresh_token_key);
      window.location.href = loginRoute;
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "user-list",
    "user-list-config",
    "view-user",
    "role-list",
    "view-role",
    "agent-list",
    "view-agent",
    "company-profile",
    "company-profile-public",
    "guideline-list",
    "bank-account-list",
    "bank-account-config",
    "payments-types-list",
    "payments-types-list-config",
    "expenses-types-list",
    "expenses-types-list-config",
    "discount-list",
    "discount-list-config",
    "promo-list",
    "promo-list-config",
    "commission-scheme",
    "lot-category-list",
    "lot-category-list-config",
    "lot-type-list",
    "lot-type-list-config",
    "lot-status-list",
    "lot-status-list-config",
    "lot-information-list",
    "lot-information-list-config",
    "crypt-level-list",
    "crypt-level-list-config",
    "crypt-column-list",
    "crypt-column-list-config",
    "crypt-status-list",
    "crypt-status-list-config",
    "crypt-information-list",
    "crypt-information-list-config",
    "vault-level-list",
    "vault-level-list-config",
    "vault-column-list",
    "vault-column-list-config",
    "vault-status-list",
    "vault-status-list-config",
    "vault-information-list",
    "vault-information-list-config",
    "buyer-information",
    "other-information",
    "buyer-identification-list",
    "buyer-unit-type",
    "buyer-unit-payment",
    "buyer-unit-payment-receipt",
    "buyer-payment-doc-list",
    "buyer-guideline",
    "buyer-list",
    "buyer-list-config",
    "rental-services-calendar-get",
    "rental-services-price-list",
    "rental-services-interment-form",
    "rental-services-waiver",
    "rental-services-requirement",
    "lead-list",
    "lead-information",
    "add-schedule-payment",
    "add-schedule",
    "service-payment-receipt",
    "or-list",
    "or-get",
    "add-schedule-inventory",
    "service-inventory-receipt",
    "account-management-payment",
    "account-management-payment-details",
    "account-management-payment-docs",
    "account-management-payment-receipt",
    "account-management-payment-data",
    "expense-list",
    "expense-item",
    "expense-file-list",
    "acknowledge-receipt-list",
    "acknowledge-receipt",
    "acknowledge-receipt-doc",
    "voucher-list",
    "voucher-doc",
    "voucher-details",
    "provisional-receipt-list",
    "provisional-receipt",
    "provisional-receipt-doc",
    "price-list-config",
    "dashboard-data",
  ],
  endpoints: (builder) => ({
    generateFile: builder.query<
      GeneratedLinkModel,
      {
        endpoint: string;
        type: string;
      }
    >({
      query: (param) => ({
        url: `${param.endpoint}?type=${param.type}`,
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    uploadDocs: builder.mutation<
      UploadDocsDtoModel,
      {
        endpoint: string;
        file: File;
        formKey: string;
      }
    >({
      query: (param) => {
        const formDataBody = new FormData();

        if (param) {
          formDataBody.append(param.formKey, param.file);
        }

        return {
          url: param.endpoint,
          method: "POST",
          body: formDataBody,
          formData: true,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
    }),
  }),
});

export const { useLazyGenerateFileQuery, useUploadDocsMutation } = apiSlice;
